import { getI18n } from '@hellodarwin/core/lib/plugins/i18n/utils';
import notification from 'antd/es/notification';

const showErrorNotification = (code: number) => {
  const { t } = getI18n();
  const message = t(`errors|${code}|message`);
  const description = t(`errors|${code}|description`);

  notification.error({
    message: message ? message : 'Server Error',
    description: description ? description : 'Error code: ' + code,
    placement: 'bottomLeft',
  });
};

export default showErrorNotification;
