import {
  AssetEntity,
  AssetFolderEntity,
  AssetRecordInformation,
  AssetWithOwnersEntity,
  InitialStateAssetEntity,
  InitialStateAssetFolderEntity,
  MappedAssets,
} from '@hellodarwin/core/lib/features/entities';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';

export interface MappedAssetsFolders {
  [key: string]: AssetFolderEntity;
}

export default class AssetsAdapter {
  sortAssetsByDate = (a: AssetEntity, b: AssetEntity) =>
    (b.uploaded_at ? new Date(b.uploaded_at).getTime() : 0) -
    (a.uploaded_at ? new Date(a.uploaded_at).getTime() : 0);

  setAllFolders = (
    mappedFolders: MappedAssetsFolders,
    folders: AssetFolderEntity[],
  ) => {
    for (let folder of folders) {
      this.addOneFolder(mappedFolders, folder);
    }

    return mappedFolders;
  };

  setAllFoldersFromRecords = (
    mappedFolders: MappedAssetsFolders,
    assets: AssetWithOwnersEntity[],
  ) => {
    mappedFolders = assets.reduce((o, asset) => {
      if (asset.records) {
        for (const record of asset.records) {
          const content: AssetFolderEntity = {
            ...o[record.record_id],
            ...record,
            mapped_assets: {
              ...o[record.record_id]?.mapped_assets,
              [asset.asset_id]: asset,
            },
          };

          o = {
            ...o,
            [record.record_id]: content,
          };
        }
      }
      return o;
    }, {} as MappedAssetsFolders);

    return mappedFolders;
  };

  addRecordAssets = (
    mappedFolders: MappedAssetsFolders,
    assets: AssetEntity[],
    record: AssetRecordInformation,
  ) => {
    if (!assets.length) return mappedFolders;

    const folder: AssetFolderEntity = {
      record_id: record.record_id,
      record_name: record.record_name ?? record.record_id,
      record_type: record.record_type,
      asset_details: assets,
      mapped_assets: {},
      updated_at: assets[0].uploaded_at ?? new Date(),
      created_at: assets[0].created_at ?? new Date(),
    };

    mappedFolders[folder.record_id] = {
      ...folder,
      mapped_assets: {},
    };

    for (let asset of assets) {
      mappedFolders[folder.record_id].mapped_assets[asset.asset_id] = asset;
    }

    return mappedFolders;
  };

  addOneFolder = (
    mappedFolders: MappedAssetsFolders,
    folder: AssetFolderEntity,
  ) => {
    mappedFolders[folder.record_id] = {
      ...folder,
      mapped_assets: {},
    };
    if (folder.asset_details) {
      for (let asset of folder.asset_details) {
        mappedFolders[folder.record_id].mapped_assets[asset.asset_id] = asset;
      }
    }

    return mappedFolders;
  };

  selectAllFolders = (mappedFolders: MappedAssetsFolders) => {
    const folders: AssetFolderEntity[] = [];

    for (let folder of Object.values(mappedFolders)) {
      folders.push({
        ...folder,
        asset_details: Object.values(folder.mapped_assets),
      });
    }

    return folders;
  };

  selectAllAssets = (mappedFolders: MappedAssetsFolders) => {
    const assets: AssetEntity[] = [];

    for (let folder of Object.values(mappedFolders)) {
      assets.push(...Object.values(folder.mapped_assets));
    }

    return assets.sort(this.sortAssetsByDate);
  };

  selectAllRecordAssets = (
    mappedFolders: MappedAssetsFolders,
    record_id: string,
  ): AssetEntity[] => {
    if (!mappedFolders[record_id]) return [];

    const assets: AssetEntity[] = !!mappedFolders[record_id]
      ? Object.values(mappedFolders[record_id].mapped_assets)
      : [];

    return assets.sort(this.sortAssetsByDate);
  };

  selectAllMappedAssets = (
    mappedFolders: MappedAssetsFolders,
  ): MappedAssets => {
    let allAssets = Object.values(mappedFolders).reduce(
      (o, folder) => ({ ...o, ...folder.mapped_assets }),
      {} as MappedAssets,
    );

    return allAssets;
  };

  selectFolderById = (
    mappedFolders: MappedAssetsFolders,
    recordId: string,
  ): AssetFolderEntity => {
    if (Object.keys(mappedFolders).length === 0)
      return InitialStateAssetFolderEntity;

    return {
      ...mappedFolders[recordId],
      asset_details: !!mappedFolders[recordId]
        ? Object.values(mappedFolders[recordId].mapped_assets)
        : [],
    };
  };

  selectAssetById = (
    mappedFolders: MappedAssetsFolders,
    recordId: string,
    assetId: string,
  ): AssetEntity => {
    if (Object.keys(mappedFolders).length === 0) return InitialStateAssetEntity;

    return {
      ...mappedFolders[recordId].mapped_assets[assetId],
    };
  };

  selectAssetByIdWithoutRecord = (
    mappedFolders: MappedAssetsFolders,
    assetId: string,
  ): AssetEntity => {
    if (Object.keys(mappedFolders).length === 0) return InitialStateAssetEntity;

    let allAssets = this.selectAllMappedAssets(mappedFolders);

    return {
      ...allAssets[assetId],
    };
  };

  updateAsset = (
    mappedFolders: MappedAssetsFolders,
    asset: AssetWithOwnersEntity,
  ) => {
    if (!!asset.records?.length) {
      for (const record of asset.records) {
        mappedFolders[record.record_id] = {
          ...mappedFolders[record.record_id],
          mapped_assets: {
            ...mappedFolders[record.record_id]?.mapped_assets,
            [asset.asset_id]: asset,
          },
        };
      }
    }
    return mappedFolders;
  };

  updateMultipleAssets = (
    mappedFolders: MappedAssetsFolders,
    assets: AssetWithOwnersEntity[],
  ) => {
    for (const key in assets) {
      mappedFolders = this.updateAsset(mappedFolders, assets[key]);
    }
    return mappedFolders;
  };

  removeAsset = (mappedFolders: MappedAssetsFolders, assetId: string) => {
    return {
      ...Object.keys(mappedFolders).reduce(
        (o, folderKey) => ({
          ...o,
          [folderKey]: {
            ...mappedFolders[folderKey],
            mapped_assets: {
              ...Object.keys(mappedFolders).reduce(
                (o, assetKey) =>
                  assetId !== assetKey
                    ? {
                        ...o,
                        [assetKey]:
                          mappedFolders[folderKey].mapped_assets[assetKey],
                      }
                    : { ...o },
                {} as MappedAssets,
              ),
            },
          },
        }),
        {} as MappedAssetsFolders,
      ),
    };
  };

  getInitialState = (): MappedAssetsFolders => {
    return {};
  };
  getSelectors = () => {
    return {
      selectAllFolders: createSelector(
        (state: RootState) => state.assets.folders,
        (folders) => this.selectAllFolders(folders),
      ),
      selectAllAssets: createSelector(
        (state: RootState) => state.assets.folders,
        (folders) => this.selectAllAssets(folders),
      ),
      selectAllMappedAssets: createSelector(
        (state: RootState) => ({
          folders: state.assets.folders,
        }),
        ({ folders }) => this.selectAllMappedAssets(folders),
      ),
      selectAllRecordAssets: createSelector(
        [
          (state: RootState, _: string) => state.assets.folders,
          (_, record_id: string) => record_id,
        ],
        (folders, record_id) => this.selectAllRecordAssets(folders, record_id),
      ),
      selectFolderById: createSelector(
        (state: RootState, folder_id: string) => ({
          folders: state.assets.folders,
          folder_id,
        }),
        ({ folders, folder_id }) => this.selectFolderById(folders, folder_id),
      ),
      selectAssetById: createSelector(
        (state: RootState, folder_id: string, record_id: string) => ({
          folders: state.assets.folders,
          folder_id,
          record_id,
        }),
        ({ folders, folder_id, record_id }) =>
          this.selectAssetById(folders, folder_id, record_id),
      ),
      selectAssetByIdWithoutRecord: createSelector(
        (state: RootState, folder_id: string) => ({
          folders: state.assets.folders,
          folder_id,
        }),
        ({ folders, folder_id }) =>
          this.selectAssetByIdWithoutRecord(folders, folder_id),
      ),
    };
  };
}

export const createAssetsAdapter = () => {
  return new AssetsAdapter();
};
