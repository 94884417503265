import Form from 'antd/es/form';
import React, { ChangeEvent, useState } from 'react';
import { PossibleCancelAnswers } from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import ReasonCheckbox from './answer-checkbox';

type CancelReasonFormProps = {
  handleCancelProject: (
    checkedBoxes: PossibleCancelAnswers,
    otherReasonSpecified: string,
  ) => void;
  handleClose: () => void;
};

const CancelReasonForm = ({
  handleClose,
  handleCancelProject,
}: CancelReasonFormProps) => {
  const theme = useTheme();
  const { t } = useTranslations();
  const [selected, setSelected] = useState<PossibleCancelAnswers>();
  const [otherReasonSpecified, setOtherReasonSpecified] = useState('');
  const handleChange = (name: PossibleCancelAnswers) => {
    setSelected(name);
  };

  const handleOtherReasonChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setOtherReasonSpecified(value);
  };

  const onSubmit = () => {
    if (!!selected) {
      handleCancelProject(selected, otherReasonSpecified);
    }
  };

  return (
    <RfpStatusFlowLayout
      title={t(`projectStatusFlow|psf_cancel_header1`)}
      description={t(`projectStatusFlow|psf_cancel_text1`)}
      buttons={
        <>
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handleClose}
            tablet={{ fullWidth: true }}
            size="small"
          >
            {t('button|cancel')}
          </Button>
          <Button onClick={onSubmit} tablet={{ fullWidth: true }} size="small">
            {t('button|submit')}
          </Button>
        </>
      }
    >
      <Form>
        <Div flex="column" gap={16}>
          {React.Children.toArray(
            (
              Object.keys(
                PossibleCancelAnswers,
              ) as (keyof typeof PossibleCancelAnswers)[]
            ).map((answer) => (
              <ReasonCheckbox
                answer={PossibleCancelAnswers[answer]}
                selected={selected}
                handleChange={handleChange}
                handleOtherReasonChange={handleOtherReasonChange}
                t={t}
                key={answer}
              />
            )),
          )}
        </Div>
      </Form>
    </RfpStatusFlowLayout>
  );
};

export default CancelReasonForm;
