import React, { useMemo } from 'react';
import { useAppSelector } from '../../../app/app-hooks';
import {
  selectRfpById,
  selectSelectedRfpId,
} from '../../../features/api/slices/rfp-slice';
import ManagerContactModal, {
  ManagerContactModalProps,
} from '../../program/modal/manager-contact-modal';

interface RfpManagerContactModalProps
  extends Omit<ManagerContactModalProps, 'adminAuthID'> {}

const RfpManagerContactModal = (props: RfpManagerContactModalProps) => {
  const rfpId = useAppSelector(selectSelectedRfpId) || '';
  const rfp = useAppSelector((state) => selectRfpById(state, rfpId));
  const adminId = useMemo(() => rfp?.account_manager || '', [rfp]);

  return <ManagerContactModal adminAuthID={adminId} {...props} />;
};

export default RfpManagerContactModal;
