import {
  CancelRfpFlowSteps,
  PossibleCancelAnswers,
  Rfp,
  RfpUpdateFunctions,
} from '../../../../features/entities';

import CancelConfirmation from './cancel-confirmation';
import CancelReasonForm from './cancel-reason-form';

type CancelRfpFlowProps = {
  handleClose: () => void;
  editionFunctions: RfpUpdateFunctions;
  rfp: Rfp;
  flowStep: CancelRfpFlowSteps;
  setFlowStep: (step: CancelRfpFlowSteps) => void;
};

const CancelRfpFlow = ({
  handleClose,
  editionFunctions,
  rfp,
  flowStep,
  setFlowStep,
}: CancelRfpFlowProps) => {
  const handleCancelRfp = (
    selected: PossibleCancelAnswers,
    otherReasonSpecified: string,
  ) => {
    if (!!editionFunctions?.cancel) {
      editionFunctions
        ?.cancel(rfp.rfp_id, selected, otherReasonSpecified)
        .then((i) => {
          setFlowStep(CancelRfpFlowSteps.cancelConfirmation);
        });
    }
  };

  return (
    <>
      {flowStep === CancelRfpFlowSteps.cancelFormStep ? (
        <CancelReasonForm
          handleClose={handleClose}
          handleCancelProject={handleCancelRfp}
        />
      ) : (
        flowStep === CancelRfpFlowSteps.cancelConfirmation && (
          <CancelConfirmation handleClose={handleClose} />
        )
      )}
    </>
  );
};

export default CancelRfpFlow;
