import {
  ApiError,
  ApiErrorInitialState,
} from '@hellodarwin/core/lib/features/entities/api-entitites';
import {
  AssetDownloadResponse,
  AssetEntity,
  AssetFolderEntity,
  AssetRecordInformation,
  AssetWithOwnersEntity,
} from '@hellodarwin/core/lib/features/entities/assets-entities';
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import {
  createAssetsAdapter,
  MappedAssetsFolders,
} from '../adapters/assets-adapter';
import ClientApiV2 from '../client-api-v2';
import SliceRequest from '../slice-request';

const foldersAdapter = createAssetsAdapter();

export interface AssetsState {
  status: 'idle' | 'pending';
  error: ApiError;
  folders: MappedAssetsFolders;
}

const initialState: AssetsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  folders: foldersAdapter.getInitialState(),
};

export const fetchCompanyAssets = SliceRequest<
  AssetEntity[],
  { api: ClientApiV2 }
>('fetchCompanyAssets', async ({ api }) => {
  const response = await api.get<AssetEntity[]>(`/assets`);
  return response.data;
});

export const fetchCompanyRecordAssets = SliceRequest<
  AssetEntity[],
  { api: ClientApiV2; record: AssetRecordInformation }
>('fetchCompanyRecordAssets', async ({ api, record }) => {
  const response = await api.get<AssetWithOwnersEntity[]>(
    `/assets?record_id=${record.record_id}&record_type=${record.record_type}`,
  );
  return response.data;
});

export const fetchCompanyFolders = SliceRequest<
  AssetFolderEntity[],
  { api: ClientApiV2 }
>('fetchCompanyFolders', async ({ api }) => {
  const response = await api.get<AssetFolderEntity[]>(`/assets/folder`);
  return response.data;
});

export const uploadAsset = SliceRequest<
  AssetWithOwnersEntity,
  { api: ClientApiV2; formData: FormData }
>('uploadAsset', async ({ api, formData }) => {
  const response = await api.post<AssetEntity>(
    `/assets`,
    formData,
    undefined,
    'multipart/form-data',
  );
  return response.data;
});

export const downloadAsset = SliceRequest<
  AssetDownloadResponse,
  { api: ClientApiV2; asset_id: string }
>('downloadAsset', async ({ api, asset_id }) => {
  return (await api.get<AssetDownloadResponse>(`/assets/download/${asset_id}`))
    .data;
});

const assetsSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyAssets.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCompanyAssets.fulfilled, (state, { payload }) => {
      foldersAdapter.updateMultipleAssets(state.folders, payload);

      state.status = 'idle';
    });
    builder.addCase(fetchCompanyAssets.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = 'idle';
    });
    builder.addCase(fetchCompanyRecordAssets.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(
      fetchCompanyRecordAssets.fulfilled,
      (state, { payload }) => {
        foldersAdapter.updateMultipleAssets(state.folders, payload);

        state.status = 'idle';
      },
    );
    builder.addCase(fetchCompanyRecordAssets.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;

      state.status = 'idle';
    });
    builder.addCase(uploadAsset.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(uploadAsset.fulfilled, (state, { payload }) => {
      foldersAdapter.updateAsset(state.folders, payload);

      state.status = 'idle';
    });
    builder.addCase(uploadAsset.rejected, (state, { payload }) => {
      state.status = 'idle';
      state.error = payload ?? ApiErrorInitialState;
    });
    builder.addCase(downloadAsset.pending, (state, { payload }) => {
      state.status = 'pending';
    });
    builder.addCase(downloadAsset.fulfilled, (state, { payload }) => {
      foldersAdapter.updateAsset(state.folders, payload.asset);

      state.status = 'idle';
    });
    builder.addCase(downloadAsset.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchCompanyFolders.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchCompanyFolders.fulfilled, (state, { payload }) => {
      foldersAdapter.setAllFolders(state.folders, payload);

      state.status = 'idle';
    });
    builder.addCase(fetchCompanyFolders.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const {
  selectAllFolders,
  selectAllAssets,
  selectAllMappedAssets,
  selectAllRecordAssets,
  selectAssetById,
  selectAssetByIdWithoutRecord,
  selectFolderById,
} = foldersAdapter.getSelectors();

export const isError = (state: RootState) =>
  state.assets.error !== ApiErrorInitialState;

export const assetsReducer = assetsSlice.reducer;
