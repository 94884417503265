import styled, { css } from '../../../../../plugins/styled';
import Card from '../../../../common/Card';
interface RfpProgressCardContainerProps {
  $isSelected?: boolean;
}

export const SelectedStyling = css`
  margin: 0;
  background-color: ${(props) => props.theme.colors.green_4};
  border: 2px solid ${(props) => props.theme.colors.green_1};
  box-shadow: none;
`;
export const RfpProgressCardContainer = styled(
  Card,
)<RfpProgressCardContainerProps>`
  margin: 1px;
  cursor: pointer;
  width: 250px;
  padding: 24px;
  border-radius: 4px;
  ${({ $isSelected }) => ($isSelected ? SelectedStyling : undefined)}
  border: 1px solid ${(props) => props.theme.colors.primary};

  &:hover {
    border: 1px solid ${(props) => props.theme.colors.purple_1};
    box-shadow: 0px 4px 10px rgba(25, 0, 51, 0.09);
    ${({ $isSelected }) => ($isSelected ? SelectedStyling : undefined)}
  }
`;
