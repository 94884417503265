import Div from '@hellodarwin/core/lib/components/common/div';
import Breadcrumbs from '@hellodarwin/core/lib/components/layout/header/breadcrumbs';
import UserMenu from '@hellodarwin/core/lib/components/layout/header/user-menu';
import { NavigationLink } from '@hellodarwin/core/lib/features/entities';
import useMediaQuery from '@hellodarwin/core/lib/features/helpers/use-media-query';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import Employees from '@hellodarwin/icons/dist/icons/Employees';
import File from '@hellodarwin/icons/dist/icons/File';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import RatingOutline from '@hellodarwin/icons/dist/icons/RatingOutline';
import User from '@hellodarwin/icons/dist/icons/User';
import Divider from 'antd/es/divider';
import Layout from 'antd/es/layout';
import { useMemo } from 'react';
import { useAppSelector } from '../app/index';
import RfpSubmissionRow from '../components/rfp/rfp-submission-row';
import { selectProfile } from '../features/api/slices/profile-slice';
import { useClientApi } from '../features/api/use-client-api';
import showErrorNotification from '../features/utils/show-error-notifications';

const { Header } = Layout;

const PageHeader = ({ isOnboarding }: { isOnboarding?: boolean }) => {
  const { t } = useTranslation();
  const api = useClientApi();
  //Selector
  const profile = useAppSelector(selectProfile);

  const openBillingSession = () => {
    (async () => {
      try {
        const session = await api.fetchBillingSession(
          window?.location?.href || '',
        );
        window.location.href = session.url;
      } catch (err: any) {
        showErrorNotification(err.response.data.error_code);
      }
    })();
  };
  //Links
  const links: NavigationLink[] = useMemo(() => {
    const nav: NavigationLink[] = [
      {
        label: t('navigation.settings'),
        pathname: '/profile/contact',
        Icon: User,
      },
      {
        label: t('navigation.users'),
        pathname: '/profile/users',
        Icon: Employees,
      },
      {
        pathname: '/files-vault',
        Icon: File,
        label: t('navigation.fileVault'),
        isNew: true,
      },
      {
        pathname: '/reviews',
        label: t('navigation.reviews'),
        Icon: RatingOutline,
      },

      {
        Icon: Grants,
        pathname: '/billing',
        label: t('navigation.billing'),
        onClick: openBillingSession,
        isExternal: true,
      },
    ];
    return nav;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Functions

  const isTablet = useMediaQuery({ maxWidth: 992 });
  if (isTablet) return <></>;

  return (
    <Header style={{ height: '40px', padding: 0 }}>
      <Div
        flex="row"
        align="center"
        justify="space-between"
        style={{ height: '100%' }}
        gap={54}
      >
        <Breadcrumbs />

        <Div flex="row" gap={31} align="center" justify="flex-end" fitContent>
          {!isOnboarding && <RfpSubmissionRow />}
          <Divider
            type="vertical"
            style={{
              borderColor: theme.colors.grey_4,
              borderWidth: 1,
              height: 31,
              top: 0,
              margin: 0,
            }}
          />
          <UserMenu
            user={{ name: profile.contact_name, logo: profile.logo }}
            links={!isOnboarding ? links : []}
            app="client"
          />
        </Div>
      </Div>
    </Header>
  );
};

export default PageHeader;
