import Button from '@hellodarwin/core/lib/components/common/button';
import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import {
  ClientRfpRequest,
  ParsedClientTag,
} from '@hellodarwin/core/lib/features/entities';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import Form from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';
import Select, { SelectProps } from 'antd/es/select';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import {
  createRfp,
  selectRfpLoading,
} from '../../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../../features/api/use-client-api';

interface RfpSubmitFormValues {
  rfp_service: string;
  rfp_budget: string;
  premium: string;
  timeline: string;
  rfp_description: string;
}

interface RfpSubmitFormProps {
  closeModal: () => void;
  openSuccesModal?: (arg0: boolean) => void;
  tags: ParsedClientTag[];
}

const RfpSubmitForm = ({
  closeModal,
  openSuccesModal,
  tags,
}: RfpSubmitFormProps) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<RfpSubmitFormValues>();
  const dispatch = useAppDispatch();
  const api = useClientApi();
  const isLoading = useAppSelector(selectRfpLoading);

  const onFinish = () => {
    try {
      form
        .validateFields()
        .then((values) => {
          (async () => {
            let minBudget: string = '0';
            let maxBudget: string = '0';

            if (values.rfp_budget) {
              [minBudget, maxBudget] = values.rfp_budget.split('-');
            }

            const rfp: ClientRfpRequest = {
              original_description: values.rfp_description,
              budget_min: parseInt(minBudget),
              budget_max: parseInt(maxBudget),
              premium: values.premium === 'yes',
              tags: [values.rfp_service],
              timeline: values.timeline,
            };

            await dispatch(createRfp({ api, rfp }));

            form.resetFields();
            closeModal();
            openSuccesModal && openSuccesModal(true);
          })();
        })
        .catch((e) => {});
    } catch (e) {}
  };

  return (
    <Div className="fade-in" align="stretch" flex="column" gap={32}>
      <Typography.Title level={2} elementTheme="h4" noMargin={false}>
        {t('project|projectForm.title')}
      </Typography.Title>
      <Form
        form={form}
        layout="vertical"
        size="large"
        onFinish={onFinish}
        className={'project-submit-form'}
      >
        <Div flex="row" tablet={{ flex: 'column' }} gap={24}>
          <Div className="project-submit-form-row">
            <Form.Item
              name="project_service"
              label={t('project|projectForm.service')}
              rules={[
                {
                  required: true,
                  message: t('project|projectForm.requiredMessage.service'),
                },
              ]}
            >
              <ServiceSelect tags={tags} />
            </Form.Item>
            <Form.Item
              name="project_budget"
              label={t('project|projectForm.budget')}
              style={{ width: '100%' }}
            >
              <SelectBudget />
            </Form.Item>
            <Form.Item
              name="timeline"
              style={{ width: '100%' }}
              label={t('project|projectForm.timeline')}
            >
              <SelectTimeline />
            </Form.Item>
          </Div>
          <Div className="project-submit-form-row description-row">
            <Form.Item
              name="project_description"
              label={t('project|projectForm.description')}
              className={'description-row-content'}
              style={{ marginBottom: '2rem', width: '100%' }}
              rules={[
                {
                  required: true,
                  message: t('project|projectForm.requiredMessage.description'),
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder={t('project|projectForm.placeholders.description')}
              />
            </Form.Item>
          </Div>
        </Div>
        <Div
          flex={'row'}
          justify={'center'}
          align={'center'}
          gap={24}
          tablet={{
            flex: 'column-reverse',
            align: 'center',
            justify: 'center',
            gap: 8,
          }}
          style={{ marginTop: 32 }}
        >
          <Button
            defaultStyle={theme.colors.white_1}
            htmlType={'reset'}
            onClick={closeModal}
            size={'small'}
            disabled={isLoading}
          >
            {t('common|button.cancel')}
          </Button>
          <Button
            size={'small'}
            htmlType={'submit'}
            onClick={onFinish}
            loading={isLoading}
          >
            {t('common|button.confirm')}
          </Button>
        </Div>
      </Form>
    </Div>
  );
};

const ServiceSelect = ({
  onChange,
  value,
  tags,
}: {
  onChange?: (value: string) => void;
  value?: string;
  tags: ParsedClientTag[];
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [];

  for (const tag of tags) {
    const suboptions: SelectProps['options'] = [];
    if (tag.services) {
      for (const service of tag.services) {
        suboptions.push({
          label: t(`tags|${service}`),
          value: service,
        });
      }

      options.push({
        label: tag.category,
        options: suboptions,
      });
    }
  }

  return (
    <Select
      placeholder={t('project|projectForm.placeholders.service')}
      size={'large'}
      options={options}
      onChange={onChange}
      value={value}
      showSearch
    />
  );
};

const SelectBudget = ({
  onChange,
  value,
}: {
  onChange?: (value: string) => void;
  value?: string;
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [
    { label: t('project|projectForm.iDontKnow'), value: '0-0' },
    { label: '$0 - $1,000', value: '0-1000' },
    { label: '$1,000 - $2,500', value: '1000-2500' },
    { label: '$2,500 - $5,000', value: '2500-5000' },
    { label: '$5,000 - $10,000', value: '5000-10000' },
    { label: '$10,000 - $25,000', value: '10000-25000' },
    { label: '$25,000 - $50,000', value: '25000-50000' },
    { label: '$50,000 - $100,000', value: '50000-100000' },
    { label: '$100,000 +', value: '100000-100000' },
  ];

  return (
    <Select
      size={'large'}
      placeholder={t('project|projectForm.placeholders.budget')}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

const SelectTimeline = ({
  onChange,
  value,
}: {
  onChange?: (value: string) => void;
  value?: string;
}) => {
  const { t } = useTranslation();

  const options: SelectProps['options'] = [
    {
      label: t('project|projectForm.selectOptions.timeline.asap'),
      value: 'As soon as possible (urgent)',
    },
    {
      label: t('project|projectForm.selectOptions.timeline.1-2'),
      value: '1 to 6 month',
    },
    {
      label: t('project|projectForm.selectOptions.timeline.3-6'),
      value: '3 to 6 month',
    },
    {
      label: t('project|projectForm.selectOptions.timeline.other'),
      value: 'other',
    },
  ];
  return (
    <Select
      size="large"
      placeholder={t('project|projectForm.placeholders.timeline')}
      options={options}
      onChange={onChange}
      value={value}
    />
  );
};

export default RfpSubmitForm;
