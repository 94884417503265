import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { assetsReducer } from '../features/api/slices/assets-slice';
import { globalReducer } from '../features/api/slices/global-slice';
import { grantsProjectsReducer } from '../features/api/slices/grants-projects-slice';
import { grantsReducer } from '../features/api/slices/grants-slice';
import { matchesReducer } from '../features/api/slices/matches-slice';
import { profileReducer } from '../features/api/slices/profile-slice';
import { programReducer } from '../features/api/slices/programs-slice';
import { resourcesReducer } from '../features/api/slices/resources-slice';
import { reviewsReducer } from '../features/api/slices/reviews-slice';
import { rfpReducer } from '../features/api/slices/rfp-slice';
import { tagsReducer } from '../features/api/slices/tags-slice';
import { wonRfpReducer } from '../features/api/slices/won-rfp-slice';

export const store = configureStore({
  reducer: {
    global: globalReducer,
    grants: grantsReducer,
    grantsProjects: grantsProjectsReducer,
    profile: profileReducer,
    program: programReducer,
    rfp: rfpReducer,
    wonRfp: wonRfpReducer,
    matches: matchesReducer,
    resources: resourcesReducer,
    tags: tagsReducer,
    reviews: reviewsReducer,
    assets: assetsReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
