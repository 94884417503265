import { ModalProps } from '@hellodarwin/core/lib/components/common/hd-modal';
import ModalLayoutSimple from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-simple';
import ModalLayoutWithMeeting from '@hellodarwin/core/lib/components/common/hd-modal/layouts/modal-with-meeting';
import { AppName } from '@hellodarwin/core/lib/features/entities';
import { getMeetingUrl } from '@hellodarwin/core/lib/features/helpers';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Timeline from '@hellodarwin/icons/dist/icons/Timeline';
import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../app/app-hooks';
import { useClientAnalytics } from '../../../../features/analytics/use-client-analytics';
import { selectProfile } from '../../../../features/api/slices/profile-slice';
import {
  fetchTags,
  selectTags,
} from '../../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../../features/api/use-client-api';
import RfpSubmitForm from './rfp-submit-form';
import SubmitRfpOptions from './submit-rfp-options';
import SubmitRfpSuccess from './submit-rfp-success';
type ClientSubmitRfpModalsProps = {
  open: boolean;
  handleClose: () => void;
};

export enum SubmitRfpFlowSteps {
  meeting = 'Meeting',
  success = 'Success',
  form = 'Form',
  options = 'Options',
}

const ClientSubmitRfpModal = ({
  open,
  handleClose,
}: ClientSubmitRfpModalsProps) => {
  const analytics = useClientAnalytics();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const dispatch = useAppDispatch();
  const api = useClientApi();
  const tags = useAppSelector(selectTags);
  const profile = useAppSelector(selectProfile);
  const [step, setStep] = useState<SubmitRfpFlowSteps>(
    SubmitRfpFlowSteps.options,
  );
  const [modalSize, setModalSize] = useState<ModalProps['size']>('full');

  useEffect(() => {
    if (!open && tags && tags.length > 0) return;
    dispatch(fetchTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (step === SubmitRfpFlowSteps.success) setModalSize('small');
    if (step === SubmitRfpFlowSteps.meeting) setModalSize('medium');
    if (step === SubmitRfpFlowSteps.options) setModalSize('large');
    if (step === SubmitRfpFlowSteps.form) setModalSize('medium');
  }, [step]);

  const handleModalClose = () => {
    setStep(SubmitRfpFlowSteps.options);
    handleClose();
    setModalSize('full');
  };

  const openMeeting = () => {
    setStep(SubmitRfpFlowSteps.meeting);
    analytics.meetingClicked();
  };

  const meetingUrl = useMemo(
    () => getMeetingUrl(selectedLocale, 'client'),
    [selectedLocale],
  );

  if (step === SubmitRfpFlowSteps.meeting) {
    return (
      <ModalLayoutWithMeeting
        open={open}
        handleCancel={handleModalClose}
        size={modalSize}
        Icon={Timeline}
        iconSize={32}
        styles={{
          body: { padding: 24, paddingTop: 54 },
          content: { padding: 0 },
        }}
        title={t('message.talkToManager')}
        meetingUrl={meetingUrl}
        name={profile.contact_name}
        email={profile.contact_email}
        app={AppName.Client}
        companyName={profile.company_name}
        phone={profile.contact_phone}
      />
    );
  }

  return (
    <ModalLayoutSimple
      open={open}
      handleCancel={handleModalClose}
      size={modalSize}
    >
      {step === SubmitRfpFlowSteps.form ? (
        <RfpSubmitForm
          closeModal={() => setStep(SubmitRfpFlowSteps.options)}
          openSuccesModal={() => setStep(SubmitRfpFlowSteps.success)}
          tags={tags}
        />
      ) : step === SubmitRfpFlowSteps.success ? (
        <SubmitRfpSuccess handleClose={handleModalClose} />
      ) : (
        step === SubmitRfpFlowSteps.options && (
          <SubmitRfpOptions setStep={setStep} openMeeting={openMeeting} />
        )
      )}
    </ModalLayoutSimple>
  );
};

export default ClientSubmitRfpModal;
