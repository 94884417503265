import { RfpStatusFlowSteps } from '.';
import {
  CancelRfpFlowSteps,
  ChoosePartnerFlowSteps,
} from '../../../features/entities';
import useMediaQuery from '../../../features/helpers/use-media-query';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Typography from '../../common/typography';

type StatusFlowFooterProps = {
  step: RfpStatusFlowSteps;
  handleChangeFlow: (step: RfpStatusFlowSteps) => void;
  cancelFlowStep: CancelRfpFlowSteps;
  choosePartnerFlowStep: ChoosePartnerFlowSteps;
  hasShortlistMatches?: boolean;
};

const StatusFlowFooter = ({
  step,
  handleChangeFlow,
  cancelFlowStep,
  choosePartnerFlowStep,
  hasShortlistMatches,
}: StatusFlowFooterProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const isCancel = [RfpStatusFlowSteps.CancelRfpFlow].includes(step);
  const isNoPartnerSelected = [
    RfpStatusFlowSteps.NoPartnerSelectedOptions,
  ].includes(step);
  const isPartnerSelected = [RfpStatusFlowSteps.PartnerChooseSelected].includes(
    step,
  );

  const isTablet = useMediaQuery({ maxWidth: 559 });

  const showCancel =
    !isCancel && choosePartnerFlowStep === ChoosePartnerFlowSteps.chosenSpStep;
  const showChoosePartner =
    !isPartnerSelected &&
    hasShortlistMatches &&
    cancelFlowStep === CancelRfpFlowSteps.cancelFormStep;

  if (isNoPartnerSelected || (!showChoosePartner && !showCancel)) return <></>;

  return (
    <Div
      flex="row"
      align="center"
      justify="space-between"
      tablet={{ flex: 'column', align: 'center', gap: '1' }}
    >
      {isTablet && (
        <Typography
          bold
          elementTheme="overline"
          style={{ letterSpacing: 0.3 }}
          textTransform="uppercase"
          color={theme.colors.purple_3}
        >
          {t('projectStatusFlow|psf_footer_wrong_status')}
        </Typography>
      )}

      <Div fitContent>
        {showCancel && (
          <Button
            onClick={() => handleChangeFlow(RfpStatusFlowSteps.CancelRfpFlow)}
            isLink
          >
            {t('projectStatusFlow|psf_footer_underlink3')}
          </Button>
        )}
      </Div>
      <Div fitContent>
        {showChoosePartner && (
          <Button
            onClick={() =>
              handleChangeFlow(RfpStatusFlowSteps.PartnerChooseSelected)
            }
            isLink
          >
            {t('projectStatusFlow|psf_footer_underlink2')}
          </Button>
        )}
      </Div>
    </Div>
  );
};

export default StatusFlowFooter;
