import Progress from 'antd/es/progress';
import { RfpProgress } from '../../../../../features/entities';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import { RfpProgressCardContainer } from './styles';

type RfpProgressCardProps = {
  title: string;
  value: RfpProgress;
  onClick: (progress: RfpProgress) => void;
  selected: boolean;
};
const RfpProgressCard = ({
  title,
  value,
  onClick,
  selected,
}: RfpProgressCardProps) => {
  const theme = useTheme();
  const handleClick = () => {
    onClick(value);
  };

  return (
    <RfpProgressCardContainer
      onClick={handleClick}
      $isSelected={selected}
      hoverable
      style={{ flex: 1, cursor: 'pointer' }}
    >
      <Div flex="column" align="center" gap={24}>
        <Progress
          type="circle"
          percent={value}
          success={{ percent: value, strokeColor: theme.colors.green_1 }}
          trailColor={theme.colors.grey_5}
          strokeColor={theme.colors.purple_1}
        />

        <Typography elementTheme="body2" textAlign="center" nowrap>
          {title}
        </Typography>
      </Div>
    </RfpProgressCardContainer>
  );
};
export default RfpProgressCard;
