import {
  ApiError,
  ApiErrorInitialState,
  Grant,
  Milestone,
  MilestoneAttachment,
  Program,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/index';
import showErrorNotification from '../../utils/show-error-notifications';
import ClientApi from '../client-api';

const programsAdapter = createEntityAdapter({
  selectId: (model: Program) => model.program_id,
});
const grantsAdapter = createEntityAdapter({
  selectId: (model: Grant) => model.grant_id,
});

const milestonesAdapter = createEntityAdapter({
  selectId: (model: Milestone) => model.milestone_id,
});

const milestonesAttachmentsAdapter = createEntityAdapter({
  selectId: (model: MilestoneAttachment) => model.milestone_attachment_id,
});

export const toggleProgramModal = createAction<{
  isVisible: boolean;
  type: string;
}>('client/toggleProgramModal');

export interface ProgramStates {
  status: 'idle' | 'pending';
  milestoneStatus: 'idle' | 'pending';
  attachmentStatus: 'idle' | 'pending';
  error: ApiError;
  programs: EntityState<Program, string>;
  consultingPrograms: EntityState<Program, string>;
  roadmapPrograms: EntityState<Program, string>;
  grants: EntityState<Grant, string>;
  milestones: EntityState<Milestone, string>;
  milestonesAttachments: EntityState<MilestoneAttachment, string>;
  modal: {
    isVisible: boolean;
    type: string;
  };
}

const initialState: ProgramStates = {
  status: 'idle',
  milestoneStatus: 'idle',
  attachmentStatus: 'idle',
  error: ApiErrorInitialState,
  programs: programsAdapter.getInitialState(),
  consultingPrograms: programsAdapter.getInitialState(),
  roadmapPrograms: programsAdapter.getInitialState(),
  grants: grantsAdapter.getInitialState(),
  milestones: milestonesAdapter.getInitialState(),
  milestonesAttachments: milestonesAttachmentsAdapter.getInitialState(),
  modal: {
    isVisible: false,
    type: '',
  },
};

export const GetProgram = createAsyncThunk<
  Program,
  { api: ClientApi; programId: string; locale: string },
  { rejectValue: ApiError }
>(
  'client/GetProgram',
  async (
    {
      api,
      programId,
      locale,
    }: { api: ClientApi; programId: string; locale: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getProgram(programId, locale);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const GetPrograms = createAsyncThunk<
  Program[],
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  'client/GetPrograms',
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.getPrograms();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const GetConsultingPrograms = createAsyncThunk<
  Program[],
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  'client/GetConsultingPrograms',
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.getConsultingPrograms();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const GetRoadmapPrograms = createAsyncThunk<
  Program[],
  { api: ClientApi; locale: string },
  { rejectValue: ApiError }
>(
  'client/GetRoadmapPrograms',
  async (
    { api, locale }: { api: ClientApi; locale: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getRoadmapPrograms(locale);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const GetMilestones = createAsyncThunk<
  Milestone[],
  { api: ClientApi; programId: string },
  { rejectValue: ApiError }
>(
  'client/GetMilestones',
  async (
    { api, programId }: { api: ClientApi; programId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.getMilestones(programId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchMilestone = createAsyncThunk<
  Milestone,
  { api: ClientApi; milestoneId: string },
  { rejectValue: ApiError }
>(
  'client/fetchMilestone',
  async (
    { api, milestoneId }: { api: ClientApi; milestoneId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchMilestone(milestoneId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchGrant = createAsyncThunk<
  Grant,
  { api: ClientApi; grantId: string; locale: string },
  { rejectValue: ApiError }
>(
  'client/fetchGrant',
  async (
    {
      api,
      grantId,
      locale,
    }: { api: ClientApi; grantId: string; locale: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchGrant(grantId, locale);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchMilestoneAttachments = createAsyncThunk<
  MilestoneAttachment[],
  { api: ClientApi; milestoneId: string },
  { rejectValue: ApiError }
>(
  'client/fetchMilestoneAttachments',
  async (
    { api, milestoneId }: { api: ClientApi; milestoneId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchMilestoneAttachments(milestoneId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const programsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetProgram.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(GetProgram.fulfilled, (state, { payload }) => {
      state.programs = programsAdapter.setOne(state.programs, payload);
      state.status = 'idle';
    });
    builder.addCase(GetProgram.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchGrant.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchGrant.fulfilled, (state, { payload }) => {
      state.grants = grantsAdapter.setOne(state.grants, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchGrant.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(GetPrograms.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(GetPrograms.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.programs = programsAdapter.setAll(state.programs, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(GetPrograms.rejected, (state, { payload }) => {
      if (payload !== null) {
        state.error = payload ?? ApiErrorInitialState;
      }
      state.status = 'idle';
    });
    builder.addCase(GetConsultingPrograms.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(GetConsultingPrograms.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.consultingPrograms = programsAdapter.setAll(
          state.consultingPrograms,
          payload,
        );
      }
      state.status = 'idle';
    });
    builder.addCase(GetConsultingPrograms.rejected, (state, { payload }) => {
      if (payload !== null) {
        state.error = payload ?? ApiErrorInitialState;
      }
      state.status = 'idle';
    });
    builder.addCase(GetRoadmapPrograms.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(GetRoadmapPrograms.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.roadmapPrograms = programsAdapter.setAll(
          state.roadmapPrograms,
          payload,
        );
      }
      state.status = 'idle';
    });
    builder.addCase(GetRoadmapPrograms.rejected, (state, { payload }) => {
      if (payload !== null) {
        state.error = payload ?? ApiErrorInitialState;
      }
      state.status = 'idle';
    });
    builder.addCase(GetMilestones.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.milestones = milestonesAdapter.setAll(state.milestones, payload);
      }
      state.milestoneStatus = 'idle';
    });
    builder.addCase(GetMilestones.pending, (state, { payload }) => {
      state.milestoneStatus = 'pending';
    });
    builder.addCase(GetMilestones.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.milestoneStatus = 'idle';
    });
    builder.addCase(fetchMilestone.pending, (state) => {
      state.milestoneStatus = 'pending';
    });
    builder.addCase(fetchMilestone.fulfilled, (state, { payload }) => {
      state.milestones = milestonesAdapter.upsertOne(state.milestones, payload);
      state.milestoneStatus = 'idle';
    });
    builder.addCase(fetchMilestone.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.milestoneStatus = 'idle';
    });
    builder.addCase(fetchMilestoneAttachments.pending, (state) => {
      state.attachmentStatus = 'pending';
    });
    builder.addCase(
      fetchMilestoneAttachments.fulfilled,
      (state, { payload }) => {
        if (payload !== null) {
          state.milestonesAttachments = milestonesAttachmentsAdapter.setAll(
            state.milestonesAttachments,
            payload,
          );
        }
        state.attachmentStatus = 'idle';
      },
    );
    builder.addCase(
      fetchMilestoneAttachments.rejected,
      (state, { payload }) => {
        state.error = payload ?? ApiErrorInitialState;
        state.attachmentStatus = 'idle';
      },
    );
    builder.addCase(toggleProgramModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const selectProgramIsLoading = (state: RootState) =>
  state.program.status === 'pending';

export const selectMilestoneIsLoading = (state: RootState) =>
  state.program.milestoneStatus === 'pending';

export const selectIsAttachmentsLoading = (state: RootState) =>
  state.program.attachmentStatus === 'pending';

export const {
  selectAll: selectAllMilestones,
  selectById: selectMilestoneById,
} = milestonesAdapter.getSelectors(
  (state: RootState) => state.program.milestones,
);
export const {
  selectAll: selectAllMilestoneAttachments,
  selectById: selectMilestoneAttachmentById,
} = milestonesAttachmentsAdapter.getSelectors(
  (state: RootState) => state.program.milestonesAttachments,
);

export const { selectAll: selectAllPrograms, selectById: selectProgramById } =
  programsAdapter.getSelectors((state: RootState) => state.program.programs);
export const {
  selectAll: selectAllConsultingPrograms,
  selectById: selectConsultingProgramById,
} = programsAdapter.getSelectors(
  (state: RootState) => state.program.consultingPrograms,
);
export const {
  selectAll: selectAllRoadmapPrograms,
  selectById: selectRoadmapProgramById,
} = programsAdapter.getSelectors(
  (state: RootState) => state.program.roadmapPrograms,
);

export const { selectAll: selectAllGrants, selectById: selectGrantById } =
  grantsAdapter.getSelectors((state: RootState) => state.program.grants);

export const selectProgramModal = (state: RootState) => state.program.modal;

export const programReducer = programsSlice.reducer;
