import styled from '../../../plugins/styled';

type StatusFlowContainerSize = 'medium' | 'large';

export const StatusFlowContainer = styled.div<{
  $size: StatusFlowContainerSize;
}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  max-width: ${({ $size }) => ($size === 'large' ? 1050 : 900)}px;
`;

export const StatusFlowContent = styled.div<{
  $isModal?: boolean;
}>`
  ${({ theme, $isModal }) =>
    !$isModal
      ? `
border-radius: 4px;
padding: 24px 56px;
  border: 1px solid ${theme.colors.primary};
  background-color: ${theme.colors.white_1};
  box-shadow: 0px 4px 14px 7px rgba(25, 0, 51, 0.04);

  @media (max-width: ${theme.breakpoints.tablet}px) {
    padding: 24px 12px;
  }
  `
      : undefined}
  display: flex;
  flex-direction: column;
  align-items: center;
`;
