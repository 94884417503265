import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  ApiError,
  ApiErrorInitialState,
  ContentfulFAQ,
  ContentfulResource,
} from '@hellodarwin/core/lib/features/entities';
import { RootState } from '../../../app/index';
import showErrorNotification from '../../utils/show-error-notifications';
import ContentfulApi from '../client-contentful-api';

export interface ProjectsState {
  status: 'idle' | 'pending';
  error: ApiError;
  resources: EntityState<ContentfulResource, string>;
  faq: EntityState<ContentfulFAQ, string>;
  tags: string[];
  selectedTag?: string;
}

const resourcesAdapter = createEntityAdapter({
  selectId: (model: ContentfulResource) => model.slug,
});
const faqAdapter = createEntityAdapter({
  selectId: (model: ContentfulFAQ) => model.id || '',
});

const initialState: ProjectsState = {
  status: 'idle',
  error: ApiErrorInitialState,
  faq: faqAdapter.getInitialState(),
  resources: resourcesAdapter.getInitialState(),
  tags: [],
  selectedTag: undefined,
};

export const getFAQ = createAsyncThunk<
  ContentfulFAQ[],
  { api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getFAQ',
  async ({ api }: { api: ContentfulApi }, { rejectWithValue }) => {
    try {
      return await api.fetchFAQ();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);
export const getResources = createAsyncThunk<
  ContentfulResource[],
  { api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getResources',
  async (
    {
      api,
    }: {
      api: ContentfulApi;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchResources();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getResourcesWithLimit = createAsyncThunk<
  ContentfulResource[],
  { limit: number; api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getResourcesWithLimit',
  async (
    { limit, api }: { limit: number; api: ContentfulApi },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchResourcesWithLimit(limit);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const getResourceBySlug = createAsyncThunk<
  ContentfulResource,
  { slug: string; api: ContentfulApi },
  { rejectValue: ApiError }
>(
  'client/getResourceBySlug',
  async (
    { slug, api }: { slug: string; api: ContentfulApi },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchResourceBySlug(slug);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

const resourcesSlice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setSelectedTag: (state, action) => {
      state.selectedTag = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResources.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResources.fulfilled, (state, { payload }) => {
      if (payload) {
        state.resources = resourcesAdapter.setAll(state.resources, payload);
      }

      state.tags = Array.from(
        new Set(
          payload
            .flatMap((resource) => resource.tags)
            .filter((tag) => tag != null),
        ),
      );
      state.status = 'idle';
    });
    builder.addCase(getFAQ.fulfilled, (state, { payload }) => {
      faqAdapter.setAll(state.faq, payload);
      state.status = 'idle';
    });
    builder.addCase(getFAQ.pending, (state, { payload }) => {
      state.status = 'pending';
    });
    builder.addCase(getFAQ.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResources.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResourcesWithLimit.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResourcesWithLimit.fulfilled, (state, { payload }) => {
      if (payload) {
        state.resources = resourcesAdapter.setAll(state.resources, payload);
      }

      state.status = 'idle';
    });
    builder.addCase(getResourcesWithLimit.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(getResourceBySlug.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getResourceBySlug.fulfilled, (state, { payload }) => {
      state.resources = resourcesAdapter.upsertOne(state.resources, payload);
      state.status = 'idle';
    });
    builder.addCase(getResourceBySlug.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
  },
});

export const resourcesReducer = resourcesSlice.reducer;
export const { setSelectedTag } = resourcesSlice.actions;

export const { selectAll: selectAllResources, selectById: selectResourceById } =
  resourcesAdapter.getSelectors(
    (state: RootState) => state.resources.resources,
  );
export const { selectAll: selectAllFAQs, selectById: selectFAQbyId } =
  faqAdapter.getSelectors((state: RootState) => state.resources.faq);

export const selectFilteredResources = createSelector(
  [selectAllResources, (_, selectedTag) => selectedTag],
  (resources, selectedTag) => {
    if (selectedTag) {
      return resources.filter((resource) =>
        resource.tags?.includes(selectedTag),
      );
    }
    return resources;
  },
);
export const selectResourcesLoading = (state: RootState) =>
  state.resources.status === 'pending';
