import Div from '@hellodarwin/core/lib/components/common/div';
import Typography from '@hellodarwin/core/lib/components/common/typography';
import { NavigationLink } from '@hellodarwin/core/lib/features/entities';
import { handleExternalLinkCLick } from '@hellodarwin/core/lib/features/helpers';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';

import { Tablet } from '@hellodarwin/core/lib/components/common/Media';
import Accueil from '@hellodarwin/icons/dist/icons/Accueil';
import AddNew from '@hellodarwin/icons/dist/icons/AddNew';
import Grants from '@hellodarwin/icons/dist/icons/Grants';
import ProjectsList from '@hellodarwin/icons/dist/icons/ProjectsList';
import Propulsion from '@hellodarwin/icons/dist/icons/Propulsion';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../app/index';
import { toggleRfpsModal } from '../../features/api/slices/rfp-slice';
import { BottomNavigationContainer } from './styles';
const BottomNavigation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleProjectSubmitModal = () => {
    dispatch(toggleRfpsModal({ isVisible: true, type: 'projectSubmit' }));
  };

  const links: NavigationLink[] = [
    {
      pathname: '/',
      label: t('navigation.home'),
      Icon: Accueil,
    },
    {
      pathname: '/projects',
      label: t('navigation.projects'),
      Icon: ProjectsList,
    },
    {
      pathname: '/submit',
      label: t('navigation.submitProject'),
      Icon: () => (
        <div
          style={{
            borderRadius: 99,
            background: theme.colors.purple_1,
            color: theme.colors.white_1,
            width: 44,
            height: 44,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 6,
          }}
        >
          <AddNew width={20} height={20} />
        </div>
      ),
      onClick: handleProjectSubmitModal,
    },
    {
      pathname: '/programs',
      label: t('navigation.programs'),
      Icon: Propulsion,
      isNew: true,
    },
    {
      pathname: '/funding-explorer',
      label: t('grants|fundingExplorer'),
      Icon: Grants,
    },
  ];

  const handleLinkClick = (link: NavigationLink) => {
    if (link.external_link) handleExternalLinkCLick(link.external_link);
    else if (link.onClick) link.onClick();
    else {
      navigate(link.pathname);
    }
  };

  const MenuItem = ({
    link,
    isMiddle,
    isActive,
  }: {
    link: NavigationLink;
    isMiddle?: boolean;
    isActive: boolean;
  }) => {
    const textColor = isMiddle
      ? theme.colors.purple_1
      : isActive
        ? theme.colors.purple_1
        : theme.colors.grey_3;

    return (
      <Div
        fitContent
        flex="column"
        align="center"
        onClick={() => handleLinkClick(link)}
        style={{ flex: 1, marginTop: isMiddle ? -44 : 0 }}
      >
        <Typography color={textColor}>
          {!!link.Icon && <link.Icon size={18} />}
        </Typography>
        <Typography
          textAlign="center"
          color={theme.colors.grey_3}
          elementTheme="caption"
          bold
          style={{ wordWrap: 'break-word' }}
        >
          {link.label}
        </Typography>
      </Div>
    );
  };

  const middleIndex = Math.round((links.length - 1) / 2);

  return (
    <Tablet direction="down">
      <BottomNavigationContainer>
        {links.map((link, i) => (
          <MenuItem
            link={link}
            key={i}
            isMiddle={middleIndex === i}
            isActive={location.pathname === link.pathname}
          />
        ))}
      </BottomNavigationContainer>
    </Tablet>
  );
};

export default BottomNavigation;
