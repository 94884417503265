import {
  ClientMappedRfp,
  Rfp,
  RfpAsset,
} from '@hellodarwin/core/lib/features/entities';

export default class RfpAdapter {
  setAll = (mappedRfps: { [key: string]: ClientMappedRfp }, rfps: Rfp[]) => {
    for (let rfp of rfps) {
      this.addOne(mappedRfps, rfp);
    }

    return mappedRfps;
  };

  addOne = (mappedRfps: { [key: string]: ClientMappedRfp }, rfp: Rfp) => {
    mappedRfps[rfp.rfp_id] = {
      ...rfp,
      assets: {} as { [key: string]: RfpAsset },
    };

    return mappedRfps;
  };

  removeOne = (
    mappedRfps: { [key: string]: ClientMappedRfp },
    rfp_id: string,
  ) => {
    delete mappedRfps[rfp_id];
    return mappedRfps;
  };

  selectAll = (mappedRfps: { [key: string]: ClientMappedRfp }) => {
    const rfps: Rfp[] = [];

    for (let rfp of Object.values(mappedRfps)) {
      rfps.push({
        ...rfp,
        assets: Object.values(rfp.assets),
      });
    }

    rfps.sort((a, b) => {
      if (a.updated_at && b.updated_at) {
        return a.updated_at.localeCompare(b.updated_at);
      }
      return 0;
    });

    rfps.reverse();

    return rfps;
  };

  selectById = (
    mappedRfps: { [key: string]: ClientMappedRfp },
    rfpId: string,
  ) => {
    if (Object.keys(mappedRfps).length === 0) return;
    if (!rfpId || !rfpId.length) return;
    if (!mappedRfps[rfpId]?.rfp_id) return;

    const rfp = {
      ...mappedRfps[rfpId],
      assets: Object.values(mappedRfps[rfpId].assets),
    } as Rfp;
    return rfp;
  };

  getInitialState = () => {
    return {} as { [key: string]: ClientMappedRfp };
  };
}

export const createRfpAdapter = () => {
  return new RfpAdapter();
};
