import { RfpStatusFlowSteps } from '.';
import { useTranslations } from '../../../features/providers/translations-provider';
import { useTheme } from '../../../plugins/styled';
import Button from '../../common/button';
import Div from '../../common/div';
import Modal from '../../common/hd-modal';
import RfpStatusFlowLayout from './rfp-status-flow-layout';

type RfpStatusFlowModalProps = {
  open: boolean;
  handleClose: () => void;
  handleClick: (step: RfpStatusFlowSteps) => void;
};

const RfpStatusFlowModal = ({
  open,
  handleClose,
  handleClick,
}: RfpStatusFlowModalProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  return (
    <Modal open={open} handleCancel={handleClose}>
      <Div flex="column" align="center">
        <RfpStatusFlowLayout
          title="Tell us about the status of your project!"
          description="We'd love to know more about where you're at with your project. Please take a minute to fill us in."
        >
          <Div flex="column" gap={48} align="center">
            <Div flex="column" gap={24}>
              <Button
                fullWidth
                defaultStyle={theme.colors.green_1}
                onClick={() =>
                  handleClick(RfpStatusFlowSteps.PartnerChooseSelected)
                }
              >
                {t('projectStatusFlow|psf_modal_button1')}
              </Button>
              <Button
                fullWidth
                onClick={() => handleClick(RfpStatusFlowSteps.CancelRfpFlow)}
              >
                {t('projectStatusFlow|psf_modal_button2')}
              </Button>
            </Div>
            <Button
              isLink
              onClick={() =>
                handleClick(RfpStatusFlowSteps.NoPartnerSelectedOptions)
              }
            >
              {t('projectStatusFlow|psf_modal_underlink1')}
            </Button>
          </Div>
        </RfpStatusFlowLayout>
      </Div>
    </Modal>
  );
};
export default RfpStatusFlowModal;
