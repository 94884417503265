import {
  RfpStatusFlowModal,
  RfpStatusFlowSteps,
} from '@hellodarwin/core/lib/components/rfp/status-flow';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/index';
import {
  selectRfpById,
  selectSelectedRfpId,
} from '../../../features/api/slices/rfp-slice';

type RfpStatusModalProps = {
  handleClose: () => void;
  open: boolean;
};

const RfpStatusModal = ({ open, handleClose }: RfpStatusModalProps) => {
  const navigate = useNavigate();

  const rfpId = useAppSelector(selectSelectedRfpId) || '';
  const rfp = useAppSelector((state) => selectRfpById(state, rfpId));

  if (!rfp) return <></>;

  const handleStatusClick = (step: RfpStatusFlowSteps) => {
    switch (step) {
      case RfpStatusFlowSteps.CancelRfpFlow:
        navigate(`/rfp/${rfp.rfp_id}/status/cancel`);
        break;
      case RfpStatusFlowSteps.PartnerChooseSelected:
        navigate(`/rfp/${rfp.rfp_id}/status/chosen-sp`);
        break;
      case RfpStatusFlowSteps.NoPartnerSelectedOptions:
        navigate(`/rfp/${rfp.rfp_id}/status/contact-manager`);
        break;
      default:
        navigate(`/rfp/${rfp.rfp_id}/status`);
        break;
    }
    handleClose();
  };

  return (
    <RfpStatusFlowModal
      open={open}
      handleClose={handleClose}
      handleClick={handleStatusClick}
    />
  );
};

export default RfpStatusModal;
