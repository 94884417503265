import React from 'react';
import {
  ChoosePartnerFlowSteps,
  Match,
  RfpUpdateFunctions,
} from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import ProviderCard from './card';

type ChosenSpProps = {
  shortlistMatches: Match[];
  setStep: (step: ChoosePartnerFlowSteps) => void;
  setSelectedMatch: (match: Match) => void;
  selectedMatch: Match;
  editionFunctions: RfpUpdateFunctions;
};

const ChosenSp = ({
  shortlistMatches,
  setStep,
  setSelectedMatch,
  selectedMatch,
  editionFunctions,
}: ChosenSpProps) => {
  const { t } = useTranslations();

  const handleNext = () => {
    if (!!editionFunctions.selectAsWinner) {
      editionFunctions.selectAsWinner(selectedMatch.match_id);
    }
    setStep(ChoosePartnerFlowSteps.progressStep);
  };

  const handleProviderClick = (match: Match) => {
    setSelectedMatch(match);
  };

  if (!shortlistMatches) return <></>;

  return (
    <RfpStatusFlowLayout
      title={t('projectStatusFlow|psf_chosenSp_header1')}
      description={t('projectStatusFlow|psf_chosenSp_text1')}
      buttons={
        <Button
          onClick={handleNext}
          tablet={{ fullWidth: true }}
          size="small"
          disabled={!selectedMatch?.provider_id}
        >
          {t('button|next')}
        </Button>
      }
    >
      <>
        <Div flex="row" gap={32} align="stretch" justify="center">
          {React.Children.toArray(
            shortlistMatches.map((m) => (
              <ProviderCard
                key={m.match_id}
                provider={m.provider}
                onClick={() => handleProviderClick(m)}
                selected={selectedMatch.provider_id === m.provider_id}
              />
            )),
          )}
        </Div>
      </>
    </RfpStatusFlowLayout>
  );
};
export default ChosenSp;
