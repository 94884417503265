import { useEffect, useState } from 'react';
import {
  ChoosePartnerFlowSteps,
  Match,
  MatchInitialState,
  Rfp,
  RfpProgress,
  RfpUpdateFunctions,
} from '../../../../features/entities';

import ChosenSp from './chosen-sp/index';
import RfpProgressStep from './progress';
import QuickReviewStep from './quick-review-flow';

type ChooseRfpPartnerFlowProps = {
  handleClose: () => void;
  editionFunctions: RfpUpdateFunctions;
  rfp: Rfp;
  shortlistMatches: Match[];
  flowStep: ChoosePartnerFlowSteps;
  setFlowStep: (step: ChoosePartnerFlowSteps) => void;
};

const ChooseRfpPartnerFlow = ({
  handleClose,
  editionFunctions,
  rfp,
  shortlistMatches,
  flowStep,
  setFlowStep,
}: ChooseRfpPartnerFlowProps) => {
  const [selectedMatch, setSelectedMatch] = useState<Match>(MatchInitialState);
  const [progress, setRfpProgress] = useState<RfpProgress | undefined>();

  useEffect(() => {
    if (editionFunctions?.selectProgression && !!progress) {
      editionFunctions.selectProgression(rfp.rfp_id, progress);
    }
  }, [progress]);

  return (
    <>
      {flowStep === ChoosePartnerFlowSteps.chosenSpStep ? (
        <ChosenSp
          shortlistMatches={shortlistMatches}
          setStep={setFlowStep}
          selectedMatch={selectedMatch}
          editionFunctions={editionFunctions}
          setSelectedMatch={setSelectedMatch}
        />
      ) : flowStep === ChoosePartnerFlowSteps.progressStep ? (
        <RfpProgressStep
          setStep={setFlowStep}
          progress={progress}
          setRfpProgress={setRfpProgress}
          provider={selectedMatch.provider}
        />
      ) : flowStep === ChoosePartnerFlowSteps.QuickReviewStep ? (
        <QuickReviewStep
          handleClose={handleClose}
          provider={selectedMatch.provider}
          editionFunctions={editionFunctions}
          rfpId={rfp.rfp_id}
          matchId={selectedMatch.match_id}
        />
      ) : (
        <></>
      )}
    </>
  );
};
export default ChooseRfpPartnerFlow;
