import ProviderRefuseModal from '@hellodarwin/core/lib/components/rfp/provider-refuse-modal';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  refuseProvider,
  selectRfpMatchById,
} from '../../../features/api/slices/matches-slice';
import { selectRfp } from '../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../features/api/use-client-api';
type RefuseModalProps = {
  open: boolean;
  handleCancel: () => void;
  matchId: string;
};

const RefuseModal = ({ open, handleCancel, matchId }: RefuseModalProps) => {
  const navigate = useNavigate();
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const selectedMatch = useAppSelector((state) =>
    selectRfpMatchById(state, matchId),
  );

  const rfp = useAppSelector(selectRfp);

  const handleRefuse = async (
    matchId: string,
    refusedReason: string,
    refusedReasonSpecified: string,
  ) => {
    (async () => {
      await dispatch(
        refuseProvider({
          api,
          matchId,
          refusedReason,
          refusedReasonSpecified,
        }),
      ).then(() => {
        setTimeout(() => {
          if (!!rfp?.rfp_id) {
            navigate(`/rfp/${rfp?.rfp_id}/candidates`);
          }
        }, 200);
      });
    })();
  };

  return (
    <>
      {selectedMatch && (
        <ProviderRefuseModal
          modalVisible={open}
          handleRefuse={handleRefuse}
          closeModal={handleCancel}
          match={selectedMatch}
        />
      )}
    </>
  );
};

export default RefuseModal;
