import {
  ChoosePartnerFlowSteps,
  Provider,
  RfpProgress,
} from '../../../../../features/entities';
import { useTranslations } from '../../../../../features/providers/translations-provider';
import { useTheme } from '../../../../../plugins/styled';
import Button from '../../../../common/button';
import Div from '../../../../common/div';
import RfpStatusFlowLayout from '../../rfp-status-flow-layout';
import RfpProgressCard from './card';
type ProgressReportStepProps = {
  setStep: (step: ChoosePartnerFlowSteps) => void;
  progress?: RfpProgress;
  provider: Provider;
  setRfpProgress: (newProgress: RfpProgress | undefined) => void;
};

const ProgressReportStep = ({
  setStep,
  progress,
  provider,
  setRfpProgress,
}: ProgressReportStepProps) => {
  const { t } = useTranslations();
  const theme = useTheme();

  const handleNext = () => {
    if (!!progress || progress == 0) {
      setStep(ChoosePartnerFlowSteps.QuickReviewStep);
    }
  };
  const handlePrev = () => {
    setStep(ChoosePartnerFlowSteps.chosenSpStep);
  };

  const handleCardClick = (newProgress: RfpProgress) => {
    setRfpProgress(newProgress);
  };

  return (
    <RfpStatusFlowLayout
      title={t('projectStatusFlow|psf_projectStatus_header1')}
      description={t('projectStatusFlow|psf_projectStatus_text1', {
        SP: provider.name,
      })}
      buttons={
        <>
          <Button
            defaultStyle={theme.colors.white_1}
            onClick={handlePrev}
            tablet={{ fullWidth: true }}
            size="small"
          >
            {t('button|previous')}
          </Button>
          <Button
            tablet={{ fullWidth: true }}
            onClick={handleNext}
            size="small"
            disabled={progress === undefined}
          >
            {t('button|next')}
          </Button>
        </>
      }
    >
      <>
        <Div flex="row" gap={16} justify="center" align="center">
          <RfpProgressCard
            title={t('projectStatusFlow|psf_projectStatus_selectionNotStarted')}
            value={RfpProgress.NotStarted}
            onClick={handleCardClick}
            selected={progress === RfpProgress.NotStarted}
          />

          <RfpProgressCard
            title={t('projectStatusFlow|psf_projectStatus_selectionInProgress')}
            value={RfpProgress.InProgress}
            onClick={handleCardClick}
            selected={progress === RfpProgress.InProgress}
          />

          <RfpProgressCard
            title={t('projectStatusFlow|psf_projectStatus_selectionCompleted')}
            value={RfpProgress.Completed}
            onClick={handleCardClick}
            selected={progress === RfpProgress.Completed}
          />
        </Div>
      </>
    </RfpStatusFlowLayout>
  );
};

export default ProgressReportStep;
