import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from '../plugins/i18n';

export enum OnBoardingSteps {
  CompanyName = 0,
  Company = 1,
  Contact = 2,
  Category = 3,
  Services = 4,
  Specialties = 5,
  Submit = 6,
  CompanyDetails = 7,
}

export enum OnBoardingStepTypes {
  Partner,
  Client,
}

interface OnBoardingContextState {
  currentStep: OnBoardingSteps;
  setCurrentStep: (step: OnBoardingSteps) => void;
  progressSteps: OnBoardingProgressSteps[];
}

export const OnBoardingContext = createContext<OnBoardingContextState>({
  currentStep: OnBoardingSteps.CompanyName,
  setCurrentStep: () => null,
  progressSteps: [],
});

type OnBoardingProviderProps = {
  stepType: OnBoardingStepTypes;
  children?: React.ReactNode;
};

export interface OnBoardingProgressSteps {
  step: OnBoardingSteps;
  label: string;
}

const OnBoardingProvider = ({
  stepType,
  children,
}: OnBoardingProviderProps) => {
  const { t } = useTranslation();

  const progressStepsMap: Record<
    OnBoardingStepTypes,
    OnBoardingProgressSteps[]
  > = {
    [OnBoardingStepTypes.Partner]: [
      {
        step: OnBoardingSteps.CompanyName,
        label: t('onboarding|sider.steps.1'),
      },
      { step: OnBoardingSteps.Company, label: t('onboarding|sider.steps.2') },
      { step: OnBoardingSteps.Contact, label: t('onboarding|sider.steps.3') },
      { step: OnBoardingSteps.Category, label: t('onboarding|sider.steps.4') },
      { step: OnBoardingSteps.Services, label: t('onboarding|sider.steps.5') },
      {
        step: OnBoardingSteps.Specialties,
        label: t('onboarding|sider.steps.6'),
      },
      { step: OnBoardingSteps.Submit, label: t('onboarding|sider.steps.7') },
    ],
    [OnBoardingStepTypes.Client]: [],
  };

  const [step, setStep] = useState<OnBoardingSteps>(
    stepType === OnBoardingStepTypes.Partner
      ? OnBoardingSteps.CompanyName
      : OnBoardingSteps.CompanyDetails,
  );

  const value = {
    currentStep: step,
    setCurrentStep: setStep,
    progressSteps: progressStepsMap[stepType],
  };

  return (
    <OnBoardingContext.Provider value={value}>
      {children}
    </OnBoardingContext.Provider>
  );
};

export default OnBoardingProvider;

export const useOnBoarding = (): OnBoardingContextState =>
  useContext(OnBoardingContext);
