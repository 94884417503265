import { Profile } from '@hellodarwin/core/lib/features/entities';
import TrackingEvent from '@hellodarwin/core/lib/features/enums/tracking-event';

type BaseAnalyticsProperties = {
  creation_source: string;
  id: string;
};

export default class ClientAnalytics {
  private readonly analytics: Window['analytics'];
  private readonly clientProfile: Profile;
  private readonly baseAnalyticsProperties: BaseAnalyticsProperties;

  constructor(analytics: Window['analytics'], clientProfile: Profile) {
    this.analytics = analytics;
    this.clientProfile = clientProfile;
    this.baseAnalyticsProperties = {
      creation_source: 'Client App',
      id: clientProfile.contact_id,
    };
  }

  projectClicked = (properties: { rfpId: string }) => {
    this.analytics?.track(TrackingEvent.ProjectClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
      ...properties,
    });
  };

  projectSubmitClicked = () => {
    this.analytics?.track(TrackingEvent.ProjectSubmitClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
    });
  };

  fundicaClicked = () => {
    this.analytics?.track(TrackingEvent.FundicaClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
    });
  };

  meetingClicked = () => {
    this.analytics?.track(TrackingEvent.MeetingClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
    });
  };

  providerClicked = (properties: { providerId: string; rfpId: string }) => {
    this.analytics?.track(TrackingEvent.ProviderClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
      ...properties,
    });
  };

  providerContactClicked = (properties: {
    clicked_provider_id: string;
    clicked_provider_contact_id: string;
    clicked_project_id: string;
  }) => {
    this.analytics?.track(TrackingEvent.ProviderContactClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
      ...properties,
    });
  };

  grantApplyNowClicked = (properties: { clicked_grant_id: string }) => {
    this.analytics?.track(TrackingEvent.GrantApplyNowClicked, {
      ...this.baseAnalyticsProperties,
      ...this.clientProfile,
      ...properties,
    });
  };
}
