import {
  ApiError,
  ApiErrorInitialState,
  Rfp,
} from '@hellodarwin/core/lib/features/entities';
import {
  EntityState,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../../../app/app-store';
import ClientApi from '../client-api';

const wonrfpAdapter = createEntityAdapter<Rfp, string>({
  selectId: (wonRfp: Rfp) => wonRfp.rfp_id,
});

export interface clientState {
  status: 'idle' | 'pending';
  error: ApiError;
  rfps: EntityState<Rfp, string>;
}

const initialState: clientState = {
  status: 'idle',
  error: ApiErrorInitialState,
  rfps: wonrfpAdapter.getInitialState(),
};

export const fetchWonRfps = createAsyncThunk<
  Rfp[],
  { api: ClientApi; providerId: string },
  { rejectValue: ApiError; state: RootState }
>(
  'client/fetchWonRfps',
  async (
    { api, providerId }: { api: ClientApi; providerId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchWonRfps(providerId);
    } catch (err: any) {
      // showErrorNotification(err.response.data);
      return rejectWithValue(err.response.data);
    }
  },
);

const wonrfpsSlice = createSlice({
  name: 'wonRfps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWonRfps.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        wonrfpAdapter.addMany(state.rfps, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(fetchWonRfps.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(fetchWonRfps.pending, (state) => {
      state.status = 'pending';
    });
  },
});

export const selectWonRfpsByProviderId = createSelector(
  [
    (state: RootState, _) => selectAllRfp(state),
    (_, providerId: string) => providerId,
  ],
  (rfps, providerId) => rfps.filter((rfp) => rfp.provider_id === providerId),
);

export const { selectAll: selectAllRfp, selectById: selectRfp } =
  wonrfpAdapter.getSelectors((state: RootState) => state.wonRfp.rfps);
export const selectRfpLoading = (state: RootState) =>
  state.wonRfp.status === 'pending';

export const wonRfpReducer = wonrfpsSlice.reducer;
