import { ReactNode } from 'react';
import Modal, { ModalProps } from '..';
import parse from '../../../../features/helpers/parse';
import Div from '../../div';
import Typography from '../../typography';
import { ModalAvatar } from '../styles';

export type ModalLayoutWithIconSize = 24 | 32 | 64 | 128;

export interface ModalLayoutWithIconProps extends ModalProps {
  avatarUrl?: string;
  avatarName: string;
  iconSize?: ModalLayoutWithIconSize;
  title: string;
  description?: string;
  warning?: string;
  children: ReactNode;
  Actions: ReactNode;
}

const ModalLayoutWithIcon = ({
  avatarUrl,
  avatarName,
  iconSize = 64,
  title,
  description,
  warning,
  children,
  Actions,
  noPadding,
  ...modalProps
}: ModalLayoutWithIconProps) => {
  const logoContainerSize = iconSize;
  const midLogoContainerSize = logoContainerSize / 2;

  return (
    <Modal
      {...modalProps}
      styles={{
        ...modalProps?.styles,
        body: {
          overflow: 'auto',
          height: '100%',
          ...modalProps?.styles?.body,
        },
        content: {
          padding: noPadding ? 0 : '32px 115px',
          paddingTop: midLogoContainerSize + 24,
          overflow: 'visible',
          maxHeight: `calc(95vh - ${midLogoContainerSize}px)`,
          ...modalProps?.styles?.content,
        },
      }}
    >
      <ModalAvatar
        style={{
          position: 'absolute',
          top: -midLogoContainerSize,
          left: '50%',
          transform: 'translateX(-50%)',
        }}
        shape="round"
        size={iconSize}
        src={avatarUrl}
      >
        {avatarName[0]}
      </ModalAvatar>
      <Div flex="column" gap={32} align="center">
        <Div flex="column" gap={16} align="center">
          <Typography.Title level={2} elementTheme="h4" textAlign="center">
            {title}
          </Typography.Title>
          {description && (
            <Typography textAlign="center">{parse(description)}</Typography>
          )}

          {!!warning && (
            <Typography
              bold
              elementTheme="body2"
              letterSpacing={0.32}
              textAlign="center"
            >
              {warning}
            </Typography>
          )}
        </Div>
        <Div>{children}</Div>

        <Div
          flex="row"
          gap={24}
          align="center"
          fitMaxContent
          style={{ width: '100%' }}
          tablet={{ flex: 'column-reverse' }}
        >
          {Actions}
        </Div>
      </Div>
    </Modal>
  );
};

export default ModalLayoutWithIcon;
