import RfpStatusFlow, {
  RfpStatusFlowSteps,
} from '@hellodarwin/core/lib/components/rfp/status-flow';
import {
  RfpInitialState,
  RfpUpdateFunctions,
} from '@hellodarwin/core/lib/features/entities';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  reviewProvider,
  selectAsWinner,
  selectShortlistedMatches,
} from '../../../features/api/slices/matches-slice';
import {
  cancelRfp,
  fetchRfp,
  selectRfpById,
  setClientRfpProgression,
  toggleRfpsModal,
} from '../../../features/api/slices/rfp-slice';
import { useClientApi } from '../../../features/api/use-client-api';

interface RfpStatusFlowModalProps {
  state: StatusFlowModalState;
  handleClose: () => void;
  open: boolean;
}

export interface StatusFlowModalState {
  initialStep?: RfpStatusFlowSteps;
  rfpId: string;
}

const RfpStatusFlowModal = ({
  state: { initialStep, rfpId },
  open,
  handleClose,
}: RfpStatusFlowModalProps) => {
  const dispatch = useAppDispatch();
  const api = useClientApi();

  const activeRfp = useAppSelector((state) =>
    selectRfpById(state, rfpId || ''),
  );

  const shortlistMatches = useAppSelector(selectShortlistedMatches);

  const editionFunctions: RfpUpdateFunctions = {
    cancel: async (rfp_id, canceled_reason, canceled_reason_specified) => {
      await dispatch(
        cancelRfp({
          api,
          rfp_id,
          canceled_reason,
          canceled_reason_specified,
        }),
      );
    },
    review: async (rfpId, providerId, reviewRequest, matchId) => {
      if (matchId === undefined) {
        matchId = '';
      }
      await dispatch(
        reviewProvider({ api, reviewRequest, providerId, rfpId, matchId }),
      );
    },

    selectAsWinner: async (matchId) => {
      await dispatch(selectAsWinner({ api, matchId }));
    },
    selectProgression: async (rfpId, clientProgression) => {
      await dispatch(
        setClientRfpProgression({ api, rfpId, clientProgression }),
      );
    },
  };

  const handleChangeFlow = (step: RfpStatusFlowSteps) => {
    switch (step) {
      case RfpStatusFlowSteps.CancelRfpFlow:
        dispatch(
          toggleRfpsModal({
            props: {
              initialStep: RfpStatusFlowSteps.CancelRfpFlow,
              rfpId: rfpId,
            },
            isVisible: true,
            type: 'projectStatusFlow',
          }),
        );
        break;
      case RfpStatusFlowSteps.PartnerChooseSelected:
        dispatch(
          toggleRfpsModal({
            props: {
              initialStep: RfpStatusFlowSteps.PartnerChooseSelected,
              rfpId: rfpId,
            },
            isVisible: true,
            type: 'projectStatusFlow',
          }),
        );
        break;
      case RfpStatusFlowSteps.NoPartnerSelectedOptions:
        dispatch(
          toggleRfpsModal({
            props: {
              initialStep: RfpStatusFlowSteps.NoPartnerSelectedOptions,
              rfpId: rfpId,
            },
            isVisible: true,
            type: 'projectStatusFlow',
          }),
        );
        break;
      default:
        dispatch(
          toggleRfpsModal({
            props: {
              initialStep: RfpStatusFlowSteps.PartnerChooseSelected,
              rfpId: rfpId,
            },
            isVisible: true,
            type: 'projectStatusFlow',
          }),
        );
        break;
    }
  };

  useEffect(() => {
    if (rfpId && activeRfp?.rfp_id !== rfpId) {
      dispatch(fetchRfp({ api, rfpId: rfpId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RfpStatusFlow
      rfp={activeRfp || RfpInitialState}
      shortlistMatches={shortlistMatches}
      editionFunctions={editionFunctions}
      initialStep={initialStep}
      handleClose={handleClose}
      handleChangeFlow={handleChangeFlow}
      isModal
      modalOpen={open}
    />
  );
};

export default RfpStatusFlowModal;
