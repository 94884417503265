import styled from '@hellodarwin/core/lib/plugins/styled';

export const RfpActionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.colors.grey_1};

  border-radius: 4px;
  padding: 24px;
  cursor: pointer;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};

  &:hover {
    background-color: ${({ theme }) => theme.colors.purple_5};
    box-shadow: ${({ theme }) => theme.appearance.box_shadow_hover};
  }
`;
