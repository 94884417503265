import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export const UTM_CACHE_KEY = 'utmParams';

export interface UTMParamsType {
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmTerm?: string;
  utmContent?: string;
}
export const InitialStateUTMParamsType: UTMParamsType = {};

export const UTMContext = createContext<UTMParamsType>(
  InitialStateUTMParamsType,
);

export const UTMProvider = ({ children }: PropsWithChildren) => {
  const location = useLocation();

  const [utmParams, setUtmParams] = useState<UTMParamsType>({});

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    setUtmParams((prevParams) => ({
      utmSource: params.get('utm_source') ?? prevParams.utmSource,
      utmMedium: params.get('utm_medium') ?? prevParams.utmMedium,
      utmCampaign: params.get('utm_campaign') ?? prevParams.utmCampaign,
      utmTerm: params.get('utm_term') ?? prevParams.utmTerm,
      utmContent: params.get('utm_content') ?? prevParams.utmContent,
    }));
  }, [location.search]);

  const value = useMemo(() => utmParams, [utmParams]);

  return <UTMContext.Provider value={value}>{children}</UTMContext.Provider>;
};

export const useUTM = () => useContext(UTMContext);

export default useUTM;
