import { useEffect, useState } from 'react';
import { Match } from '../../../features/entities';
import {
  Rfp,
  RfpUpdateFunctions,
} from '../../../features/entities/rfp-entities';
import {
  CancelRfpFlowSteps,
  ChoosePartnerFlowSteps,
} from '../../../features/entities/rfp-status-flow-entities';
import Div from '../../common/div';
import ModalLayoutSimple from '../../common/hd-modal/layouts/modal-simple';
import CancelRfpFlow from './cancel-rfp-flow';
import ChooseRfpPartnerFlow from './choose-partner-flow';
import NoPartnerSelectedFlow from './no-partner-selected-flow';
import StatusFlowFooter from './status-flow-footer';
import { StatusFlowContainer, StatusFlowContent } from './styles';

export enum RfpStatusFlowSteps {
  NoPartnerSelectedOptions = 'noPartnerSelectedOptions',
  PartnerChooseSelected = 'partnerChooseSelected',
  CancelRfpFlow = 'Cancel Rfp Flow',
}

interface RfpStatusFlowProps {
  initialStep?: RfpStatusFlowSteps;
  editionFunctions: RfpUpdateFunctions;
  rfp: Rfp;
  shortlistMatches: Match[];
  handleClose: () => void;
  handleChangeFlow: (status: RfpStatusFlowSteps) => void;
  isModal?: boolean;
  modalOpen?: boolean;
}

interface FlowContentProps extends RfpStatusFlowProps {
  step: RfpStatusFlowSteps;
  cancelFlowStep: CancelRfpFlowSteps;
  setCancelFlowStep: (step: CancelRfpFlowSteps) => void;
  choosePartnerFlowStep: ChoosePartnerFlowSteps;
  setChoosePartnerFlowStep: (step: ChoosePartnerFlowSteps) => void;
}
const FlowContent = ({
  editionFunctions,
  rfp,
  step,
  cancelFlowStep,
  setCancelFlowStep,
  choosePartnerFlowStep,
  setChoosePartnerFlowStep,
  handleClose,
  shortlistMatches,
  isModal,
}: FlowContentProps) => {
  return (
    <StatusFlowContent
      className="fade-in"
      $isModal={isModal}
      style={{ width: '100%' }}
    >
      {step === RfpStatusFlowSteps.CancelRfpFlow ? (
        <CancelRfpFlow
          handleClose={handleClose}
          editionFunctions={editionFunctions}
          rfp={rfp}
          flowStep={cancelFlowStep}
          setFlowStep={setCancelFlowStep}
        />
      ) : step === RfpStatusFlowSteps.NoPartnerSelectedOptions ? (
        <NoPartnerSelectedFlow handleClose={handleClose} />
      ) : (
        step === RfpStatusFlowSteps.PartnerChooseSelected && (
          <ChooseRfpPartnerFlow
            handleClose={handleClose}
            editionFunctions={editionFunctions}
            rfp={rfp}
            shortlistMatches={shortlistMatches}
            flowStep={choosePartnerFlowStep}
            setFlowStep={setChoosePartnerFlowStep}
          />
        )
      )}
    </StatusFlowContent>
  );
};

const RfpStatusFlow = (props: RfpStatusFlowProps) => {
  const {
    initialStep,
    rfp,
    shortlistMatches,
    modalOpen,
    handleChangeFlow,
    isModal,
    handleClose,
  } = props;

  const [editableRfp, setEditableRfp] = useState(rfp);
  const [step, setStep] = useState<RfpStatusFlowSteps>(
    initialStep || RfpStatusFlowSteps.PartnerChooseSelected,
  );

  const [choosePartnerFlowStep, setChoosePartnerFlowStep] =
    useState<ChoosePartnerFlowSteps>(ChoosePartnerFlowSteps.chosenSpStep);

  const [cancelFlowStep, setCancelFlowStep] = useState<CancelRfpFlowSteps>(
    CancelRfpFlowSteps.cancelFormStep,
  );

  useEffect(() => {
    if (!!initialStep) {
      setStep(initialStep);
    }
  }, [initialStep]);

  useEffect(() => {
    setCancelFlowStep(CancelRfpFlowSteps.cancelFormStep);
    setChoosePartnerFlowStep(ChoosePartnerFlowSteps.chosenSpStep);
  }, [step]);

  useEffect(() => {
    if (!!rfp.rfp_id) setEditableRfp(rfp);
  }, [rfp]);

  if (!editableRfp.rfp_id) return <></>;

  return isModal ? (
    <ModalLayoutSimple
      open={!!modalOpen}
      handleCancel={handleClose}
      size={
        step === RfpStatusFlowSteps.PartnerChooseSelected ? 'large' : 'medium'
      }
    >
      <Div flex="column" gap={54}>
        <FlowContent
          {...props}
          rfp={editableRfp}
          step={step}
          cancelFlowStep={cancelFlowStep}
          setCancelFlowStep={setCancelFlowStep}
          choosePartnerFlowStep={choosePartnerFlowStep}
          setChoosePartnerFlowStep={setChoosePartnerFlowStep}
        />

        <StatusFlowFooter
          step={step}
          handleChangeFlow={handleChangeFlow}
          cancelFlowStep={cancelFlowStep}
          choosePartnerFlowStep={choosePartnerFlowStep}
          hasShortlistMatches={!!shortlistMatches.length}
        />
      </Div>
    </ModalLayoutSimple>
  ) : (
    <StatusFlowContainer
      $size={
        step === RfpStatusFlowSteps.NoPartnerSelectedOptions
          ? 'large'
          : 'medium'
      }
    >
      <FlowContent
        {...props}
        rfp={editableRfp}
        step={step}
        cancelFlowStep={cancelFlowStep}
        setCancelFlowStep={setCancelFlowStep}
        choosePartnerFlowStep={choosePartnerFlowStep}
        setChoosePartnerFlowStep={setChoosePartnerFlowStep}
      />
      <StatusFlowFooter
        step={step}
        handleChangeFlow={handleChangeFlow}
        cancelFlowStep={cancelFlowStep}
        choosePartnerFlowStep={choosePartnerFlowStep}
        hasShortlistMatches={!!shortlistMatches.length}
      />
    </StatusFlowContainer>
  );
};

export default RfpStatusFlow;

export { default as RfpStatusFlowModal } from './rfp-status-flow-modal';
