import { API_URL } from '@hellodarwin/core/lib/api';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import { useMemo } from 'react';
import ClientApi from './client-api';
import ClientApiV2 from './client-api-v2';

export const useClientApi = () => {
  const { accessToken } = useLogin();
  return useMemo(() => new ClientApi(API_URL, accessToken), [accessToken]);
};

export const useNewClientApi = () => {
  const { accessToken } = useLogin();
  return useMemo(() => new ClientApiV2(API_URL, accessToken), [accessToken]);
};
