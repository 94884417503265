import styled from '@hellodarwin/core/lib/plugins/styled';

export const BottomNavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  box-shadow: 0px -6px 20px 0px rgba(92, 92, 92, 0.16);
  border-radius: 15px 15px 0px 0px;
  padding: 22px 20px;
  z-index: 200;
  backdrop-filter: blur(4px);
  background-color: rgba(255, 255, 255, 0.9);
`;
