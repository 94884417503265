import { ReactNode } from 'react';
import Div from '../../common/div';
import Typography from '../../common/typography';

type RfpStatusFlowLayoutProps = {
  title?: string;
  description?: string;
  children: ReactNode;
  buttons?: ReactNode;
};

const RfpStatusFlowLayout = ({
  title,
  description,
  children,
  buttons,
}: RfpStatusFlowLayoutProps) => {
  const Header = () => {
    return (
      <Div flex="column" gap={12} align="center" style={{ maxWidth: 470 }}>
        {!!title && (
          <Typography.Title
            level={1}
            elementTheme="h5"
            textAlign="center"
            noMargin
          >
            {title}
          </Typography.Title>
        )}
        {!!description && (
          <Typography textAlign="center">{description}</Typography>
        )}
      </Div>
    );
  };
  return (
    <Div
      className={'fade-in'}
      style={{ margin: '0 auto' }}
      flex="column"
      gap={32}
      align="center"
    >
      <Header />

      <Div flex="column" gap={32}>
        <Div>{children}</Div>
        {buttons && (
          <Div
            flex="row"
            gap={16}
            justify="center"
            tablet={{ justify: 'stretch', flex: 'column', align: 'center' }}
          >
            {buttons}
          </Div>
        )}
      </Div>
    </Div>
  );
};
export default RfpStatusFlowLayout;
