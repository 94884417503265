import ReviewFormModal from '@hellodarwin/core/lib/components/reviews/review/review-form-modal';
import { RfpUpdateFunctions } from '@hellodarwin/core/lib/features/entities';
import { useAppDispatch, useAppSelector } from '../../../app/index';
import {
  reviewProvider,
  selectRfpMatchById,
} from '../../../features/api/slices/matches-slice';
import { useClientApi } from '../../../features/api/use-client-api';

type LeaveReviewModalProps = {
  open: boolean;
  handleCancel: () => void;
  matchId: string;
};

const LeaveReviewModal = ({
  open,
  handleCancel,
  matchId,
}: LeaveReviewModalProps) => {
  const api = useClientApi();
  const dispatch = useAppDispatch();

  const selectedMatch = useAppSelector((state) =>
    selectRfpMatchById(state, matchId),
  );

  const editionFunctions: RfpUpdateFunctions = {
    review: async (rfpId, providerId, reviewRequest, matchId) => {
      if (matchId === undefined) {
        matchId = '';
      }
      await dispatch(
        reviewProvider({
          api,
          reviewRequest,
          rfpId,
          matchId,
          providerId,
        }),
      );
    },
  };
  if (!selectedMatch?.provider || !selectedMatch?.rfp_id) return <></>;

  return (
    <ReviewFormModal
      open={open}
      rfp_id={selectedMatch.rfp_id}
      provider={selectedMatch.provider}
      matchId={selectedMatch.match_id}
      handleClose={handleCancel}
      editionFunctions={editionFunctions}
    />
  );
};
export default LeaveReviewModal;
