import {
  AdminGrantResult,
  AdminProfile,
  BillingSession,
  ClientOnboardRequest,
  ClientOnBoardResponse,
  ClientRfpRequest,
  Company,
  CompanyDuplicateResponse,
  Contact,
  FormsSubmissionPreview,
  FormSubmissionResponse,
  Grant,
  GrantFinancingType,
  GrantProject,
  GrantResult,
  GrantService,
  GrantTotalAmounts,
  Industry,
  Match,
  Milestone,
  MilestoneAttachment,
  NextMeeting,
  ParsedClientTag,
  Portfolio,
  Profile,
  Program,
  Province,
  ReviewRequest,
  ReviewResult,
  ReviewVerified,
  Rfp,
  RfpDescription,
  ShortlistParams,
  TranslatedFormResponse,
} from '@hellodarwin/core/lib/features/entities';
import { UTMParamsType } from '@hellodarwin/core/lib/features/providers/utm-provider';
import axios, { AxiosInstance } from 'axios';
import { QueryFundingExplorerProps } from './client-api-entities';

export default class ClientApi {
  private readonly baseURL: string;
  private readonly accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseURL = baseUrl;
    this.accessToken = accessToken;
  }

  createAxios = (contentType: string = 'application/json'): AxiosInstance => {
    return axios.create({
      baseURL: this.baseURL + '/client',
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  };

  //Profile
  me = async (utmParams: UTMParamsType): Promise<Profile> => {
    const response = await this.createAxios().post('/me', {
      utm: utmParams,
    });
    return response.data;
  };

  updateProfile = async (data: FormData): Promise<Profile> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/profile`,
      data,
    );
    return response.data;
  };

  fetchContact = async (): Promise<Contact> => {
    const response = await this.createAxios().get(`/contact`);
    return response.data;
  };

  updateContactById = async (data: FormData): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/users`,
      data,
    );
    return response.data;
  };
  updateContact = async (data: FormData): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/contact`,
      data,
    );
    return response.data;
  };

  fetchCompany = async (): Promise<Company> => {
    const response = await this.createAxios().get(`/company`);
    return response.data;
  };

  updateCompany = async (data: FormData): Promise<Company> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/company`,
      data,
    );
    return response.data;
  };

  updateLogo = async (companyId: string, data: FormData): Promise<Profile> => {
    const response = await this.createAxios('multipart/form-data').put<Profile>(
      `/profile/${companyId}/picture`,
      data,
    );
    return response.data;
  };

  deleteLogo = async (companyId: string): Promise<Profile> => {
    const response = await this.createAxios().delete<Profile>(
      `/profile/${companyId}/picture`,
    );
    return response.data;
  };

  fetchMatch = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().get<Match>(
      `/matchmaking/${matchId}`,
    );
    return response.data;
  };

  fetchRaisehands = async (rfpId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/matchmaking/raisehands/${rfpId}`,
    );
    return response.data;
  };

  fetchRefusedMatches = async (rfpId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/matchmaking/refused/${rfpId}`,
    );
    return response.data;
  };

  fetchShortlist = async (rfpId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/matchmaking/shortlist/${rfpId}`,
    );
    return response.data;
  };

  //rfp
  fetchRfp = async (rfpId: string): Promise<Rfp> => {
    const response = await this.createAxios().get<Rfp>(`/rfp/${rfpId}`);
    return response.data;
  };

  fetchRfpMatches = async (rfpId: string): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>(
      `/rfp/${rfpId}/matches`,
    );
    return response.data;
  };

  fetchAllRfp = async (limit: number): Promise<Rfp[]> => {
    const response = await this.createAxios().get<Rfp[]>(`/rfp?limit=${limit}`);
    return response.data;
  };

  fetchWonRfps = async (providerId: string): Promise<Rfp[]> => {
    const response = await this.createAxios().get<Rfp[]>(
      `/rfp/won/${providerId}`,
    );
    return response.data;
  };

  createRfp = async (rfp: ClientRfpRequest): Promise<Rfp> => {
    const response = await this.createAxios().post<Rfp>(`/rfp`, rfp);

    return response.data;
  };

  fetchRfpDescription = async (rfpId: string): Promise<RfpDescription[]> => {
    const response = await this.createAxios().get<RfpDescription[]>(
      `/rfpDescription/${rfpId}`,
    );

    return response.data;
  };

  //Provider

  fetchTags = async (): Promise<{ [key: string]: ParsedClientTag }> => {
    const response = await this.createAxios().get<{
      [key: string]: ParsedClientTag;
    }>(`/tags`);

    return response.data;
  };

  fetchIndustries = async (locale: string): Promise<Industry[]> => {
    const response = await this.createAxios().get<Industry[]>(
      `/tags/industries?locale=${locale}`,
    );

    return response.data;
  };

  fetchPortfoliosById = async (providerId: string): Promise<Portfolio[]> => {
    const response = await this.createAxios().get<Portfolio[]>(
      `/providers/${providerId}/portfolios`,
    );
    return response.data;
  };

  //Matches
  shortlist = async (params: ShortlistParams): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/shortlist`,
      params,
    );

    return response.data;
  };

  shortlistMessage = async (params: ShortlistParams): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/shortlistMessage`,
      params,
    );

    return response.data;
  };

  refuseProvider = async (
    matchID: string,
    refuseReason: string,
    refuseReasonSpecified: string,
  ): Promise<Match> => {
    const response = await this.createAxios().put(
      `/matchmaking/${matchID}/refuse`,
      {
        refused_reason: refuseReason,
        refused_reason_specified: refuseReasonSpecified,
      },
    );

    return response.data;
  };

  selectAsWinner = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put(
      '/v2/matchmaking/' + matchId + '/winner',
    );

    return response.data;
  };

  viewProvider = async (matchId: string): Promise<string> => {
    const response = await this.createAxios().put(
      'matchmaking/' + matchId + '/view',
    );

    return response.data;
  };

  fetchAdmin = async (adminId: string): Promise<AdminProfile> => {
    const response = await this.createAxios().get('/admins/' + adminId);
    return response.data;
  };

  fetchContactById = async (contactId: string): Promise<Contact> => {
    const response = await this.createAxios().get('/contacts/' + contactId);

    return response.data;
  };

  //Reviews
  reviewProvider = async (review: ReviewRequest): Promise<ReviewResult> => {
    review.review.verified = ReviewVerified.Verified;
    const response = await this.createAxios().post('/reviews', review);

    return response.data;
  };

  createCompanyIfNotExist = async (company: Company): Promise<Company> => {
    const response = await this.createAxios().post('/reviews/company', company);

    return response.data;
  };

  createContactIfNotExist = async (contact: Contact): Promise<Contact> => {
    const response = await this.createAxios().post('/reviews/contact', contact);

    return response.data;
  };

  fetchPublicReviewsById = async (
    providerId: string,
  ): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(
      `/reviews/public/${providerId}`,
    );

    return response.data;
  };

  fetchMatchReview = async (
    providerId: string,
    rfpId: string,
  ): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(
      `/reviews/match/${providerId}/${rfpId}`,
    );

    return response.data;
  };

  fetchReviews = async (company_id: string): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(
      `/reviews/client/${company_id}`,
    );

    return response.data;
  };

  getReviewURL = async (providerId: string, rfpId: string): Promise<string> => {
    const response = await this.createAxios().get(
      `/reviews/url/${providerId}/${rfpId}`,
    );
    return response.data;
  };

  //meeting
  fetchNextMeeting = async (inviteeEmail: string): Promise<NextMeeting> => {
    const response = await this.createAxios().get(`/meeting/${inviteeEmail}`);

    return response.data;
  };

  rescheduleNextMeeting = async (
    inviteeEmail: string,
  ): Promise<NextMeeting> => {
    const response = await this.createAxios().put(`/meeting/${inviteeEmail}`);

    return response.data;
  };

  cancelNextMeeting = async (inviteeEmail: string): Promise<NextMeeting> => {
    const response = await this.createAxios().delete(
      `/meeting/${inviteeEmail}`,
    );

    return response.data;
  };

  cancelRfp = async (
    rfp_id: string,
    canceled_reason: string,
    canceled_reason_specified: string,
  ): Promise<string> => {
    const response = await this.createAxios().put(`/rfp/${rfp_id}/cancel`, {
      canceled_reason,
      canceled_reason_specified,
    });

    return response.data;
  };

  askMoreProvider = async (rfpId: string, message: string): Promise<Rfp> => {
    const response = await this.createAxios().put(
      `/rfp/${rfpId}/request_more_providers_reason`,
      {
        ask_more_provider: message,
      },
    );

    return response.data;
  };

  setClientRfpProgression = async (
    rfp_id: string,
    client_progression: number,
  ): Promise<string> => {
    const response = await this.createAxios().put(
      `/rfp/${rfp_id}/client-progression`,
      {
        client_progression,
      },
    );

    return response.data;
  };

  getProgram = async (programId: string, locale: string): Promise<Program> => {
    const response = await this.createAxios().get(
      `/programs/${programId}?locale=${locale}`,
    );
    return response.data;
  };

  getPrograms = async (): Promise<Program[]> => {
    const response = await this.createAxios().get(`/programs`);
    return response.data || [];
  };
  getConsultingPrograms = async (): Promise<Program[]> => {
    const response = await this.createAxios().get(`/programs/consulting`);
    return response.data || [];
  };
  getRoadmapPrograms = async (locale: string): Promise<Program[]> => {
    const response = await this.createAxios().get(
      `/programs/roadmap?locale=${locale}`,
    );
    return response.data || [];
  };

  getMilestones = async (programId: string): Promise<Milestone[]> => {
    const response = await this.createAxios().get(`/milestones/${programId}`);
    return response.data;
  };
  fetchMilestone = async (milestoneId: string): Promise<Milestone> => {
    const response = await this.createAxios().get(
      `/milestones/single/${milestoneId}`,
    );
    return response.data;
  };

  fetchGrant = async (grantId: string, locale: string): Promise<Grant> => {
    const response = await this.createAxios().get(
      `/grants/${grantId}?locale=${locale}`,
    );
    return response.data;
  };

  fetchMilestoneAttachments = async (
    milestoneId: string,
  ): Promise<MilestoneAttachment[]> => {
    const response = await this.createAxios().get(
      `/milestones/${milestoneId}/attachments`,
    );
    return response.data;
  };

  fetchCreditBalance = async (): Promise<number> => {
    const response = await this.createAxios().get<number>('/balance');

    return response.data;
  };

  fetchBillingSession = async (returnUrl: string): Promise<BillingSession> => {
    const response = await this.createAxios().get(
      `/billing/session?returnUrl=${returnUrl}`,
    );

    return response.data;
  };
  fetchCompanyUsers = async (): Promise<Contact[]> => {
    const response = await this.createAxios().get<Contact[]>(`/users`);

    return response.data;
  };

  deleteCompanyUser = async (contactID: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/users/${contactID}`,
    );

    return response.data;
  };

  upsertCompanyUser = async (data: FormData): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/users`,
      data,
    );
    return response.data;
  };

  sendInvitationEmail = async (email: string): Promise<Contact> => {
    const response = await this.createAxios().post(
      `/company/invitationEmail/${email}`,
    );
    return response.data;
  };

  sendRequestToJoinCompanyEmail = async (companyId: string): Promise<void> => {
    await this.createAxios().post(`/company/requestEmail/${companyId}`);
  };

  getContactByEmail = async (email: string): Promise<Contact> => {
    const response = await this.createAxios().get(`/contacts/email/${email}`);
    return response.data;
  };

  queryFundingExplorer = async ({
    locale,
    page,
    limit,
    textQuery,
    companyId,
    filterBy,
    industry,
    subindustry,
    status,
    financingType,
    region,
    closingDate,
    service,
    selectedProjects,
    annualRevenue,
    companySize,
    fieldChanged,
    valueChanged,
  }: QueryFundingExplorerProps): Promise<GrantResult[]> => {
    let url = `/grants/fundingExplorer?locale=${locale}&page=${page}&limit=${limit}`;
    const financingTypeString = financingType?.toString();
    const industryString = industry?.toString();
    const subindustryString = subindustry?.toString();
    const statusString = status?.toString();
    const regionString = region?.toString();
    const serviceString = service?.toString();
    const selectedProjectsString = selectedProjects?.toString();

    if (textQuery !== undefined) {
      url += `&textQuery=${textQuery}`;
    }

    if (companyId !== undefined) {
      url += `&companyId=${companyId}`;
    }

    if (filterBy !== undefined) {
      url += `&filterBy=${filterBy}`;
    }

    if (industry !== undefined && industry!.length > 0) {
      url += `&industry=${industryString}`;
    }

    if (subindustry !== undefined && subindustry!.length > 0) {
      url += `&subindustry=${subindustryString}`;
    }

    if (financingType !== undefined && financingType!.length > 0) {
      url += `&financingType=${financingTypeString}`;
    }

    if (status !== undefined && status!.length > 0) {
      url += `&status=${statusString}`;
    }

    if (region !== undefined && region!.length > 0) {
      url += `&region=${regionString}`;
    }

    if (annualRevenue !== undefined && annualRevenue !== '') {
      url += `&annualRevenue=${annualRevenue}`;
    }

    if (companySize !== undefined && companySize !== '') {
      url += `&companySize=${companySize}`;
    }

    if (closingDate !== undefined) {
      const dateObject = new Date(closingDate);
      const millisecondsSince1970 = dateObject.getTime();
      url += `&closingDate=${millisecondsSince1970}`;
    }

    if (service !== undefined && service!.length > 0) {
      url += `&service=${serviceString}`;
    }

    if (selectedProjects !== undefined && selectedProjects!.length > 0) {
      url += `&selectedProjects=${selectedProjectsString}`;
    }

    if (fieldChanged !== undefined && fieldChanged !== '') {
      url += `&fieldChanged=${fieldChanged}`;
    }

    if (valueChanged !== undefined && valueChanged !== '') {
      url += `&valueChanged=${valueChanged}`;
    }

    const response = await this.createAxios().get<GrantResult[]>(url);
    return response.data;
  };

  fetchGrantFinancingType = async (
    locale: string,
  ): Promise<GrantFinancingType[]> => {
    const response = await this.createAxios().get<GrantFinancingType[]>(
      `grants/financingType?locale=${locale}`,
    );
    return response.data;
  };

  fetchProvinces = async (locale: string): Promise<Province[]> => {
    const response = await this.createAxios().get<Province[]>(
      `grants/province?locale=${locale}`,
    );
    return response.data;
  };

  fetchGrantById = async (grant_id: string): Promise<AdminGrantResult> => {
    const response = await this.createAxios().get<AdminGrantResult>(
      `/grants/admin/${grant_id}`,
    );
    return response.data;
  };

  fetchGrantService = async (locale: string): Promise<GrantService[]> => {
    const response = await this.createAxios().get<GrantService[]>(
      `grants/service?locale=${locale}`,
    );
    return response.data;
  };

  fetchFundingExplorerTotalAmount = async ({
    filterBy,
    financingType,
    industry,
    subindustry,
    status,
    region,
    locale,
    service,
    selectedProjects,
    textQuery,
    companyId,
    companySize,
    closingDate,
    page,
    limit,
    annualRevenue,
  }: QueryFundingExplorerProps): Promise<number> => {
    let url = `/grants/fundingExplorer/totalAmount?locale=${locale}&page=${page}&limit=${limit}`;
    const financingTypeString = financingType?.toString();
    const industryString = industry?.toString();
    const subindustryString = subindustry?.toString();
    const statusString = status?.toString();
    const regionString = region?.toString();
    const serviceString = service?.toString();
    const selectedProjectsString = selectedProjects?.toString();

    if (textQuery !== undefined) {
      url += `&textQuery=${textQuery}`;
    }

    if (companyId !== undefined) {
      url += `&companyId=${companyId}`;
    }

    if (filterBy !== undefined) {
      url += `&filterBy=${filterBy}`;
    }

    if (industry !== undefined && industry!.length > 0) {
      url += `&industry=${industryString}`;
    }

    if (subindustry !== undefined && subindustry!.length > 0) {
      url += `&subindustry=${subindustryString}`;
    }

    if (financingType !== undefined && financingType!.length > 0) {
      url += `&financingType=${financingTypeString}`;
    }

    if (status !== undefined && status!.length > 0) {
      url += `&status=${statusString}`;
    }

    if (region !== undefined && region!.length > 0) {
      url += `&region=${regionString}`;
    }

    if (closingDate !== undefined) {
      const dateObject = new Date(closingDate);
      const millisecondsSince1970 = dateObject.getTime();
      url += `&closingDate=${millisecondsSince1970}`;
    }

    if (service !== undefined && service!.length > 0) {
      url += `&service=${serviceString}`;
    }

    if (selectedProjects !== undefined && selectedProjects!.length > 0) {
      url += `&selectedProjects=${selectedProjectsString}`;
    }

    if (annualRevenue !== undefined && annualRevenue !== '') {
      url += `&annualRevenue=${annualRevenue}`;
    }

    if (companySize !== undefined && companySize !== '') {
      url += `&companySize=${companySize}`;
    }

    const response = await this.createAxios().get(url);
    return response.data;
  };

  fetchGrantsTotals = async (): Promise<GrantTotalAmounts[]> => {
    const response =
      await this.createAxios().get<GrantTotalAmounts[]>(`/grants/totals`);
    return response.data;
  };

  onboard = async (
    data: ClientOnboardRequest,
  ): Promise<ClientOnBoardResponse> => {
    const response = await this.createAxios().post<ClientOnBoardResponse>(
      '/profile/onboard',
      data,
    );

    return response.data;
  };

  checkDuplicateCompany = async (
    companyName: string,
  ): Promise<CompanyDuplicateResponse[]> => {
    const response = await this.createAxios().get<CompanyDuplicateResponse[]>(
      `/companies/duplicates/${companyName}`,
    );

    return response.data;
  };

  fetchBestGrantProjects = async (
    locale: string,
    grantIds: string[],
  ): Promise<GrantProject[]> => {
    const response = await this.createAxios().get<GrantProject[]>(
      `/grant-projects/best?locale=${locale}`,
      {
        params: { grantIds },
      },
    );
    return response.data;
  };
  fetchGrantProjectsWithIds = async (
    locale: string,
    projectsIds: string[],
  ): Promise<GrantProject[]> => {
    const response = await this.createAxios().get<GrantProject[]>(
      `/grant-projects?locale=${locale}`,
      {
        params: { projectsIds },
      },
    );
    return response.data;
  };

  fetchGrantQualificationForm = async (
    grant_id: string,
    locale: string,
  ): Promise<TranslatedFormResponse | undefined> => {
    try {
      const response = await this.createAxios().get(
        `/forms/${grant_id}?locale=${locale}`,
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return undefined;
    }
  };
  fetchFormSubmissionByGrantId = async (
    grant_id: string,
  ): Promise<FormsSubmissionPreview[]> => {
    try {
      const response = await this.createAxios().get(
        `/forms/submission/grant/${grant_id}`,
      );
      return response.data;
    } catch (error: any) {
      console.error(error);
      return [];
    }
  };

  createFormSubmission = async (
    submission: FormSubmissionResponse,
  ): Promise<FormSubmissionResponse> => {
    const response = await this.createAxios().post<FormSubmissionResponse>(
      `/forms/submission`,
      submission,
    );
    return response.data;
  };
}
