import { useState } from 'react';
import { Provider } from '../../../../../features/entities/providers-entities';
import { ReviewRequest } from '../../../../../features/entities/reviews-entities';
import { RfpUpdateFunctions } from '../../../../../features/entities/rfp-entities';
import { ReviewFormSteps } from '../../../../../features/entities/rfp-status-flow-entities';
import ReviewForm from '../../../../reviews/review/review-form';

type QuickReviewStepProps = {
  handleClose: () => void;
  editionFunctions: RfpUpdateFunctions;
  provider: Provider;
  rfpId: string;
  matchId: string;
  companyTags?: string[];
};

const QuickReviewStep = ({
  handleClose,
  editionFunctions,
  provider,
  rfpId,
  matchId,
  companyTags,
}: QuickReviewStepProps) => {
  const handleReviewProvider = async (reviewRequest: ReviewRequest) => {
    if (!!editionFunctions.review) {
      editionFunctions.review(
        rfpId,
        provider.provider_id,
        reviewRequest,
        matchId,
      );
    }
  };

  const [reviewStep, setReviewStep] = useState<ReviewFormSteps>(
    ReviewFormSteps.Experience,
  );
  return (
    <ReviewForm
      handleClose={handleClose}
      providerId={provider.provider_id}
      rfpId={rfpId}
      providerName={provider.name}
      reviewProvider={handleReviewProvider}
      setReviewStep={setReviewStep}
      reviewStep={reviewStep}
      tags={provider.tags}
      companyTags={companyTags ?? []}
    />
  );
};

export default QuickReviewStep;
