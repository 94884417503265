import {
  createAction,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';

import {
  ApiError,
  ApiErrorInitialState,
  ClientMappedRfp,
  ClientRfpRequest,
  Company,
  Contact,
  Match,
  ParsedClientTag,
  Rfp,
  RfpDescription,
  ShortlistParams,
} from '@hellodarwin/core/lib/features/entities';
import { RootState } from '../../../app/app-store';
import { StatusFlowModalState } from '../../../components/rfp/rfp-action-modal/rfp-status-flow-modal';
import showErrorNotification from '../../utils/show-error-notifications';
import { createRfpAdapter } from '../adapters/rfp-adapter';
import ClientApi from '../client-api';

const rfpAdapter = createRfpAdapter();

export interface RfpModalState {
  isVisible: boolean;
  type: string;
  props?: StatusFlowModalState;
}
export interface RfpState {
  status: 'idle' | 'pending';
  error: ApiError;
  rfp: { [key: string]: ClientMappedRfp };
  selectedRfp: {
    rfpId: string;
  };
  tags: { [key: string]: ParsedClientTag };
  modal: RfpModalState;
}

const initialState: RfpState = {
  status: 'idle',
  error: ApiErrorInitialState,
  rfp: rfpAdapter.getInitialState(),
  selectedRfp: { rfpId: '' },
  tags: {} as { [key: string]: ParsedClientTag },
  modal: {
    isVisible: false,
    type: '',
  },
};

export const fetchRfp = createAsyncThunk<
  Rfp,
  { api: ClientApi; rfpId: string },
  { rejectValue: ApiError }
>(
  'client/fetchRfp',
  async (
    { api, rfpId }: { api: ClientApi; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchRfp(rfpId);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchAllRfp = createAsyncThunk<
  Rfp[],
  { api: ClientApi; limit: number },
  { rejectValue: ApiError }
>(
  'client/fetchAllRfp',
  async (
    { api, limit }: { api: ClientApi; limit: number },
    { rejectWithValue },
  ) => {
    try {
      return await api.fetchAllRfp(limit);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createRfp = createAsyncThunk<
  Rfp,
  { api: ClientApi; rfp: ClientRfpRequest },
  { rejectValue: ApiError; state: RootState }
>(
  'client/createRfp',
  async (
    { api, rfp }: { api: ClientApi; rfp: ClientRfpRequest },
    { rejectWithValue },
  ) => {
    try {
      return await api.createRfp(rfp);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { rfp } = getState();
      if (rfp.status === 'pending') return false;
    },
  },
);

export const askMoreProviderRfp = createAsyncThunk<
  Rfp,
  { api: ClientApi; rfpId: string; askMoreProviderMessage: string },
  { rejectValue: ApiError; state: RootState }
>(
  'client/askMoreProviders',
  async (
    {
      api,
      rfpId,
      askMoreProviderMessage,
    }: { api: ClientApi; rfpId: string; askMoreProviderMessage: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.askMoreProvider(rfpId, askMoreProviderMessage);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { rfp } = getState();
      if (rfp.status === 'pending') return false;
    },
  },
);

export const sendShortlistMessage = createAsyncThunk<
  Match,
  { api: ClientApi; matchId: string; shortlistMessage: string },
  { rejectValue: ApiError; state: RootState }
>(
  'client/sendShortlistMessage',
  async (
    {
      api,
      matchId,
      shortlistMessage,
    }: { api: ClientApi; matchId: string; shortlistMessage: string },
    { rejectWithValue },
  ) => {
    try {
      return await api.shortlistMessage({
        matchId: matchId,
        shortlist_message: shortlistMessage,
      } as ShortlistParams);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { rfp } = getState();
      if (rfp.status === 'pending') return false;
    },
  },
);

export const fetchRfpDescription = createAsyncThunk<
  RfpDescription[],
  { api: ClientApi; rfpId: string },
  { rejectValue: ApiError }
>(
  'client/fetchRfpDescription',
  async (
    { api, rfpId }: { api: ClientApi; rfpId: string },
    { rejectWithValue },
  ) => {
    try {
      const rfpDescription = api.fetchRfpDescription(rfpId);
      return rfpDescription;
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createCompanyIfNotExist = createAsyncThunk<
  Company,
  {
    api: ClientApi;
    company: Company;
  },
  { rejectValue: ApiError }
>(
  'client/createContactifNotExist',
  async (
    {
      api,
      company,
    }: {
      api: ClientApi;
      company: Company;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createCompanyIfNotExist(company);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const createContactIfNotExist = createAsyncThunk<
  Contact,
  {
    api: ClientApi;
    contact: Contact;
  },
  { rejectValue: ApiError }
>(
  'client/createContactifNotExist',
  async (
    {
      api,
      contact,
    }: {
      api: ClientApi;
      contact: Contact;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.createContactIfNotExist(contact);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const fetchTags = createAsyncThunk<
  { [key: string]: ParsedClientTag },
  { api: ClientApi },
  { rejectValue: ApiError }
>(
  'client/fetchTags',
  async ({ api }: { api: ClientApi }, { rejectWithValue }) => {
    try {
      return await api.fetchTags();
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
);

export const cancelRfp = createAsyncThunk<
  string,
  {
    api: ClientApi;
    rfp_id: string;
    canceled_reason: string;
    canceled_reason_specified: string;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'client/cancelRfp',
  async (
    {
      api,
      rfp_id,
      canceled_reason,
      canceled_reason_specified,
    }: {
      api: ClientApi;
      rfp_id: string;
      canceled_reason: string;
      canceled_reason_specified: string;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.cancelRfp(
        rfp_id,
        canceled_reason,
        canceled_reason_specified,
      );
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { rfp } = getState();
      if (rfp.status === 'pending') return false;
    },
  },
);

export const setClientRfpProgression = createAsyncThunk<
  string,
  {
    api: ClientApi;
    rfpId: string;
    clientProgression: number;
  },
  { rejectValue: ApiError; state: RootState }
>(
  'client/setClientRfpProgression',
  async (
    {
      api,
      rfpId,
      clientProgression,
    }: {
      api: ClientApi;
      rfpId: string;
      clientProgression: number;
    },
    { rejectWithValue },
  ) => {
    try {
      return await api.setClientRfpProgression(rfpId, clientProgression);
    } catch (err: any) {
      showErrorNotification(err.response.data.error_code);
      return rejectWithValue(err.response.data);
    }
  },
  {
    condition: (_, { getState }) => {
      const { rfp } = getState();
      if (rfp.status === 'pending') return false;
    },
  },
);

export const toggleRfpsModal = createAction<RfpModalState>(
  'client/toggleRfpsModal',
);

const rfpSlice = createSlice({
  name: 'rfp',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchRfp.fulfilled, (state, { payload }) => {
      state.rfp = rfpAdapter.addOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(fetchRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });

    builder.addCase(fetchAllRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllRfp.fulfilled, (state, { payload }) => {
      if (payload !== null) {
        state.rfp = rfpAdapter.setAll(state.rfp, payload);
      }
      state.status = 'idle';
    });
    builder.addCase(fetchAllRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(createRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createRfp.fulfilled, (state, { payload }) => {
      rfpAdapter.addOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(createRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(setSelectedRfpId, (state, { payload }) => {
      state.selectedRfp.rfpId = payload;
    });
    builder.addCase(fetchTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchTags.fulfilled, (state, { payload }) => {
      state.tags = payload;
      state.status = 'idle';
    });
    builder.addCase(fetchTags.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(cancelRfp.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(cancelRfp.fulfilled, (state, { payload }) => {
      state.rfp = rfpAdapter.removeOne(state.rfp, payload);
      state.status = 'idle';
    });
    builder.addCase(cancelRfp.rejected, (state, { payload }) => {
      state.error = payload ?? ApiErrorInitialState;
      state.status = 'idle';
    });
    builder.addCase(toggleRfpsModal, (state, { payload }) => {
      state.modal = payload;
    });
  },
});

export const selectRfp = createSelector(
  [
    (state: RootState) => state.rfp.rfp,
    (state: RootState) => state.rfp.selectedRfp.rfpId,
  ],
  (rfp, rfpId) => {
    return rfp[rfpId];
  },
);

export const selectSelectedRfpId = (state: RootState) =>
  state.rfp.selectedRfp.rfpId;

export const setSelectedRfpId = createAction<string>('client/setSelectedRfpId');
export const selectAllRfp = createSelector(
  (state: RootState) => state.rfp.rfp,
  (rfp) => rfpAdapter.selectAll(rfp),
);
export const selectRfpLoading = (state: RootState) =>
  state.rfp.status === 'pending';
export const selectRfpById = createSelector(
  [(state: RootState, _) => state.rfp.rfp, (_, rfpId: string) => rfpId],
  (rfp, rfpId) => rfpAdapter.selectById(rfp, rfpId),
);

export const selectTags = createSelector(
  (state: RootState) => state.rfp.tags,
  (tags) => Object.values(tags),
);

export const selectRfpState = (state: RootState) => state.rfp;

export const selectRfpModal = (state: RootState) => state.rfp.modal;
export const rfpReducer = rfpSlice.reducer;
